/*************************************

    HOME PAGEs

    = hm-features
    = hm-features-2
    = hm-about
    = spa-menu
      ¬ spa-banner

    = hm-stat
    = our-clients = testimonials
      ¬ blockquote
      ¬ control-panel
    = hm-gallery-2

*************************************/

.hm-features {
  text-align: center;
  font-size: 8px;

  &__row {
    padding: 35px 0;
    margin-left: -16px;
  }

  &__item {
    padding: 18/14 + em 0 13/14 + em;
    margin: 15px 0;
    border-left: 1px solid $border-light;

  }

  &__icon {
    min-height: 88px;
    padding-bottom: 24px;
    text-align: center;

    .svg {
      display: inline-block;
      height: 64px;
      fill: $brand-warning;
    }
  }

  span.h2 {
    font-size: 30/14 + em;
  }

  @include media('<320px') {
    &__item {
      width: 100%;
      margin: 10px 0;
      padding: 0 15px;
    }
  }

  @include media('>xs') {
    font-size: 10px;
  }

  @include media('>md') {
    font-size: 12px;

    &__row {
      max-width: 1820px;
      margin: 0 auto;
    }
    &__item {
      max-width: 385px;
      width: 20%;

      &:first-child {
        border-left-width: 0;
      }
    }
  }

  @include media('>1440px') {
    font-size: 14px;
  }
}


/**        = hm features-2         **/
.hm-features-2 {
  text-align: center;

  .item {
    margin-bottom: 30/18 + em;

  }

  &__item {
    width: 100%;
    max-width: 360px;
    padding: 45px 40/360*100% 32px;
    margin: 0 auto;
    background-color: #fafafa;
    border: 1px solid #ccc;
    border-radius: 0px 0px 2px 2px;
  }

  &__icon {
    min-height: 80px;
    padding-bottom: 25px;
    line-height: 0;

    [class^=pe-7s] {
      color: $brand-warning;
      font-size: 55px;
      line-height: 1;
    }

    img {
      display: inline-block;
      margin: 0 auto;
    }
  }

  span.h2 {
    line-height: 1;
    &:after {
      @extend %bullet-rotate;
      margin: 14px auto 12px;
    }
  }

  @include media ('<md') {
    &__item {
      padding: 45px 5%;
    }
  }

  @include media('<555px') {
    .item {
      float: none;
      width: 100%;
    }
  }
}


.hm-about {

  &__main {
    background-color: #fff;
    padding: 50px 65px 50px 50px;
  }

  &__heading {
    margin-bottom: 20px;

    h2 {
      display: inline-block;
      margin-bottom: 0;
      line-height: 1;
      font-size: 48/18 + em;
      font-weight: 700;

      &:after {
        display: block;
        content: ' ';
        width: 100%;
        height: 1px;
        margin-top: 20px;
        background-color: $brand-warning;
      }
    }
  }

  &__btn {
    background-color: #fff;
    margin-top: 44px;
  }

  &__list-mode {
    padding: 40px 30px;
    font-family: $lato;
    font-weight: 700;
    font-size: 14/18 + em;
    text-transform: uppercase;
    background-color: $bg-lighter;

    > li {
      padding: 6/14 + em 0;
      &:nth-last-child(n+2) {
        border-bottom: 1px solid $hr-border;
      }

      > span {
        display: inline-block;
        width: 50%;
        line-height: 30/14 + em;
        letter-spacing: $ls;
        color: $gray-light;

        &:last-child {
          text-align: right;
        }

        &.disabled {
          color: $gray-light;
        }

        &:not(.disabled):last-child {
          color: $brand-warning;
        }
      }
    }
  }


  @include media('<md') {
    &__heading {
      text-align: center;
    }

    &__btn {
      position: relative;
      z-index: 10;
      margin-bottom: 57/18 + em;
    }

    .btn {
      width: 100%;
    }
  }

  @include media('<480px') {
    &__main {
      padding: 50px 20px;
    }

    &__btn {
      padding: 0 20px;
      margin: 44px -20px 57/18 + em;
    }

    &__list-mode {
      margin: 0 -20px;

      > li > span {
        letter-spacing: $ls-xs;
      }
    }
  }
}


.spa-menu {
  &__row {
    margin-bottom: 20px;
  }

  &__item {
    position: relative;
    padding: 30/18 + em 0 10px 98px;
    @include transition--all();

    &:nth-last-of-type(n+2) {
      border-bottom: 1px solid $border-light;
    }
  }

  &__thumb {
    position: absolute;
    top: 30px;
    max-width: 83px;
    margin-left: -98px;
    margin-bottom: 19px;
    @include transition--all();

    img {
      border-radius: 2px;
    }
  }

  &__heading {
    margin-top: -6px;
  }

  &__cost {
    @extend %text-brand-xs;
    line-height: 30px;
  }

  .control-panel {
    margin-bottom: 30/18 + em;
  }

  @include media('<md') {
    &__content {
      .row > [class^=col]:first-child {
        border-bottom: 1px solid $border-light;
      }
    }
  }

  @include media('<xs') {
    &__item {
      padding-left: 0;
    }

    &__thumb {
      position: static;
      float: left;
      width: 90px;
      max-width: none;
      margin: 19px 17px 6px 0;
    }

    p {
      margin: 13px 0 0;
    }
  }
}


.spa-banner {
  position: relative;
  margin-top: -1px;
  border-top-right-radius: $border-radius-base + 6px;
  margin-bottom: 30px;
  background-color: $base-bg;

  img {
    border-top-right-radius: $border-radius-base + 6px;
  }

  &__bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 25px 50/555*100%;
    margin-top: -1px;
    background-color: rgba(#000, .7);

    @include media('<xs') {
      position: static;
      background-color: $gray-dark;
    }
  }

  &__title {
    font-family: $playfair;
    color: #fff;
    font-size: 30/18 + em;
    line-height: 1;
  }

  span.disabled {
    @extend %text-brand;
    color: $gray-light;
    text-decoration: line-through;
  }

  &__sale {
    @extend %text-brand-lg;
    margin-left: 10px;
  }

  span.disabled,
  &__sale {
    letter-spacing: normal;
  }
}


.hm-stat {
  line-height: 1;
  text-align: center;
  padding: 80/18 + em 0 30/18 + em;

  &__item {
    margin-bottom: 50/18 + em;
  }

  @include media ('<md') {
    font-size: 14px;
  }
}

.our-clients {
  &__wrapper {
    padding: 0;
  }

  &__item {
    padding: 0 15px;
  }

  &__main {
    position: relative;
    background-color: #fff;
    font-size: 1em;
    border-radius: $border-radius-base;
    padding: 24px 30px;
    margin-bottom: 15px;
    transition: all .4s;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      @include triangle(down, #fff, 1.4rem, 0.7rem);
    }

    blockquote {
      font-size: 1em;
      padding-left: 48px;
      margin: 0;
      margin-left: -48px;

      p  {
        &:before {
          font-size: 105px;
        }
      }

      footer {
        font-size: 100%;
        line-height: 30/18 + em;
      }
    }
  }

  &__thumb {
    display: block;
    width: 100px;
    height: 100px;
    margin: 0 auto;
    background-color: #ccc;
    border: 2px solid $brand-warning;
    border-radius: 50%;
    overflow: hidden;

    img {
      border-radius: 50%;
    }
  }


  .control-panel {
    margin: 65/18 + em 15px 0;
  }

  @include media('<md') {
    &__main {
      padding-left: 15px;
      padding-right: 15px;

      blockquote {
        font-size: 14px;

        p:before {
          left: 15px;
        }
      }
    }
  }
}


/**        ¬ hm-gallery-2         **/
.hm-gallery-2 {

  .panels-wrapper {
    min-height: auto;
    padding-bottom: 72px;
  }

  .panel-board {
    padding-bottom: 0;
  }

  &__header {
    padding: 50/18 + em;
    text-align: center;
    background-color: $bg-lighter;

    h1, .h1 {
      @extend .panel-board__title;
      margin: 0;
    }
  }

  &__slides {
    padding: 0;
  }

  &__slides-inner {
    max-width: 960px;
    margin-left: -8px;
    float: right;
  }

  &__item {
    float: left;
    padding: 0;
    padding-left: 8px;
    padding-bottom: 8px;
  }

  @include media('<sm') {
    &__header {
      h1, .h1 {
        display: inline-block !important;
      }
    }

    .panel-board {
      text-align: left;
      padding-top: 40px;
    }
  }
}