/*!
 * Datepicker for Bootstrap
 *
 * Copyright 2012 Stefan Petre
 * Improvements by Ashley Novak
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 */

 @mixin datepicker-button-variant($color, $background, $border) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:focus,
  &:active,
  &.active {
    color: $color;
    background-color: $background;//darken($background, 8%);
    border-color: darken($border, 12%);
  }

  &:hover{
    color: $color;
    background-color: darken($background, 8%);
    border-color: darken($border, 12%);
  }
}

.datepicker {
  table{
    tr {
      td, th {
        text-align: center;
        width: 30px;
        height: 30px;
        border-radius: 2px;

        border: none;
      }
    }
  }
  // Inline display inside a table presents some problems with
  // border and background colors.
  .table-striped & table tr {
    td, th {
      background-color:transparent;
    }
  }
  table tr td {
    &.day:hover, &.day.focused {
      background: $gray-lighter;
      cursor: pointer;
    }
    &.old,
    &.new {
      color: $btn-link-disabled-color;
    }
    &.disabled,
    &.disabled:hover {
      background: none;
      color: $btn-link-disabled-color;
      cursor: default;
    }
    &.today,
    &.today:hover,
    &.today.disabled,
    &.today.disabled:hover {
      $today-bg: rgba($brand-warning, .75);
      @include datepicker-button-variant($gray-dark, $today-bg, darken($today-bg, 20%));
    }
    &.today:hover:hover { // Thank bootstrap 2.0 for this selector...
      // TODO: Bump min BS to 2.1, use $textColor in buttonBackground above
      color: #000;
    }
    &.today.active:hover {
      color: #fff;
    }
    &.range,
    &.range:hover,
    &.range.disabled,
    &.range.disabled:hover {
      background:$gray-lighter;
      border-radius: 0;
    }
    &.range.today,
    &.range.today:hover,
    &.range.today.disabled,
    &.range.today.disabled:hover {
      $today-bg: rgba($brand-warning, .75);
      @include datepicker-button-variant(#000, $today-bg, darken($today-bg, 20%));
      border-radius: 0;
    }
    &.selected,
    &.selected:hover,
    &.selected.disabled,
    &.selected.disabled:hover {
      @include datepicker-button-variant(#fff, $gray-light, $gray);
      text-shadow: 0 -1px 0 rgba(0,0,0,.25);
    }
    &.active,
    &.active:hover,
    &.active.disabled,
    &.active.disabled:hover {
      @include datepicker-button-variant(#fff, rgba($brand-warning, .75), rgba($brand-warning, .75));
      text-shadow: 0 -1px 0 rgba(0,0,0,.25);
    }
    span {
      display: block;
      width: 23%;
      height: 54px;
      line-height: 54px;
      float: left;
      margin: 1%;
      cursor: pointer;
      border-radius: 2px;
      &:hover {
        background: $gray-lighter;
      }
      &.disabled,
      &.disabled:hover {
        background: none;
        color: $btn-link-disabled-color;
        cursor: default;
      }
      &.active,
      &.active:hover,
      &.active:hover.active:hover,
      &.active.disabled,
      &.active.disabled:hover {
        @include datepicker-button-variant(#fff, rgba($brand-warning, .75), rgba($brand-warning, .75));
        text-shadow: 0 -1px 0 rgba(0,0,0,.25);
      }
    }
  }

  thead tr:first-child th,
  tfoot tr th {
    &:hover{
      background: $gray-lighter;
    }
  }
}