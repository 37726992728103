/*************************************

    PAGE FOOTER

    =page-footer
      ¬ title
      ¬ list
      ¬ link
      ¬ copyright

    = instagram-top-list
    = twitter-posts
      ¬ blog-posts
      ¬ date-twit

*************************************/


.page-footer {
  position: relative;
  background-color: $gray-dark;
  color: #fff;
  font-size: 14px;
  padding-top: 50px;
  z-index: 1;

  p {
    margin-bottom: 0;
    line-height: 20/14 + em;
  }

  a {
    color: #fff;

    &:focus {
      color: $link-hover-color;
    }
  }

  .link {
    color: $brand-warning;

    &:focus {
      box-shadow: 0 1px #fff;
    }
  }

  [class*=col-] {
    margin-bottom: 50px;
  }

  &__title {
    color: $gray-light;
    line-height: 1;
    margin-top: 0;
    margin-bottom: 30/18 + em;
  }

  &__list {
    margin-top: -5px;
    > li {
      padding: 11px 0;
      border-bottom: 1px solid #616161;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        border-bottom-width: 0;
      }
    }
  }

  &__copyright {
    padding: 25px 0;
    text-align: center;
    background-color: $gray-base;
  }
}

.instagram-top-list {
  margin-left: -14px;

  a {
    position: relative;
    float: left;
    display: block;
    height: 78px;
    width: 78px;
    margin-left: 14px;
    margin-bottom: 15px;
    overflow: hidden;
  }
}

.blog-posts a {
  box-shadow: none;
}

.twitter-posts {
  padding-left: 20px;

  > li {
    padding-bottom: 15px;
  }

  .fa {
    float: left;
    margin-left: -20px;
    margin-top: 3px;
    color: #1da1f2;
  }

  .date-twit {
    color: $gray-light;

    &:hover,
    &:focus {
      box-shadow: 0 1px $link-hover-color;
    }
  }
}