/*************************************

    SIDEBAR

    = blog-sidebar
      ¬ __subscribe
      ¬ __category
      ¬ __title

    = categories-list
    = sidebar-preview
    = post-meta
    = sidebar-thumb

*************************************/


.blog-sidebar {
  background-color: #fafafa;
  padding: 50px 30/262*100% 50-36px;
  border: 1px solid $hr-border;
  font-size: 14px;
  color: #000;

  .btn {
    width: 100%;
    padding: 15px 0;
  }

  a {
    color: $brand-warning;


    &:hover {
      color: $link-hover-color;
    }

    &:focus {
      color: $gray-base;
    }
  }

  &__subscribe {
    margin-bottom: 50px;
  }

  &__category {
    margin-bottom: 36px;
  }

  p {
    line-height: 1.43em;
    margin-bottom: 1.57em;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 25px;
    font-family: 'Lato', sans-serif;
    line-height: 1;

    &:before {
      @extend %bullet-rotate;
      @extend %bullet-inline;
    }
  }

  @include media('<xs') {
    margin: 0 -15px;
    border-width: 1px 0;
  }
}

.categories-list {
  @include clearfix();

  > li {
    a {
      display: block;
      padding: 11px 0;
      border-bottom: 1px solid $gray-lighter;
      transition: none;

      span {
        @include transition--all();
      }

      &:hover {
        span {
          color: $link-hover-color;
        }
      }

      &:focus {
        span {
          color: $gray-base;
        }
      }
    }

    &:last-child a {
      border-bottom-color: transparent;
    }
  }

  .meta-count {
    float: right;
  }
}

.sidebar-preview {
  > li {
    + li {
      padding-top: 15px;
    }
    &:nth-last-child(n+2) {
      border-bottom: 1px solid $gray-lighter;
    }
  }

  &__content {
    min-height: 57px;
  }

  .post-meta {
    @include clearfix();
    padding: 9px 0 12px;

    a.comment {
      float: right;
    }

    a.date,
    a.comment {
      color: $gray-light;

      &:hover {
        color: $link-hover-color;
      }

      &:focus {
        color: $gray-dark;
      }
    }
  }
}

.sidebar-thumb {
  position: relative;
  float: left;
  width: 48px;
  margin-top: 3px;
  margin-right: 13px;
  background-color: $base-bg;
  border-radius: $border-radius-base;
}


.categories-list a,
.blog-tags a {
  font-weight: 700;
}

.blog-sidebar__title,
.categories-list a,
.blog-tags a {
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

@include media('>md', '<lg') {
  .categories-list a,
  .blog-tags a {
    letter-spacing: 0.1em;
  }

  .blog-sidebar__title {
    font-size: 16px;
    letter-spacing: 0.04em;
  }

  .blog-sidebar a {
    font-size: 13px;
  }
}