/*************************************

    Mobile menu Slideout.js

************************************/

$triggerColor: $gray-dark;
$menuMobileWidth: 256px;

.menu-trigger {
  position: relative;
  float: right;
  width: 40px;
  height: 30px;
  cursor: pointer;

  &:before {
    position: absolute;
    top: 13px;
    left: 0;
    width: 100%;
    height: 4px;
    background: $triggerColor;
    box-shadow: 0 6px transparent, 0 -8px $triggerColor, 0 18px transparent, 0 8px $triggerColor;
    content: '';
    transition: all .31s ease;
  }

  &:hover:before {
    box-shadow: 0 6px transparent, 0 -12px $triggerColor, 0 18px transparent, 0 12px $triggerColor;
  }
}

.slideout-menu {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  width: $menuMobileWidth;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: none;
}

.slideout-panel {
  position:relative;
  z-index: 1;
}

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
  overflow: hidden;
}

.slideout-open {
  .page-header,
  .page-content {
    // padding-right: 17px;
  }
}

.slideout-open .slideout-menu {
  display: block;
}

.navbar,
.page-footer {
  transition: all .3s ease;

  &.open {
    transform: translate3d($menuMobileWidth, 0, 0);
  }
}

.slideout-open .slideout-menu {
  display: block;
}

.over-page {
  visibility: hidden;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0;
  background: rgba(0,0,0, .6);
  transform: translate3d(0, 0, 0);
  transition: all .3s ease;

  &.open {
    opacity: .3;
    visibility: visible;
    background: #000;
    z-index: 1040;
    transform: translate3d($menuMobileWidth, 0, 0);
    transition: opacity .3s ease .3s;
  }
}


.panel-menu {
  background-color: #fff;

  .panel-menu-header {
    width: 256px;
    text-align: center;
    cursor: pointer;

    h1 {
      padding: ((78px - 36) / 2) 0;
      margin: 0;
      line-height: 1;
      font-size: 36px;
    }
  }

  &-content {
    position: absolute;
    top: 78px;
    left: 0;
    right: 0;
    bottom: 75px;

    > .panel-list {
      -webkit-overflow-scrolling:touch;
      overflow: scroll;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  &-heading {
    display: block;
    padding: 16px 20px;
    line-height: 1;
    margin: 0;
    text-transform: uppercase;
    text-align: center;

    &:hover, &:focus, &:active {
      color: $headings-color;
    }
  }

  .icon-back {
    position: absolute;
    left: -100%;
    opacity: 0;
    font-size: 24px;
    line-height: 20px;
    color: $brand-warning;
    transition: left .3s ease-in-out, opacity .3s ease .15s;
  }

  .panel-list {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;

    > li > a {
      display: block;
      padding: 10px 20px;
      border-bottom: 1px solid $border-light;
    }

    a {
      text-transform: uppercase;
      font-size:  13px;
    }
  }

  &-footer {
    position: absolute;
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .social-list {
    text-align: center;
    padding: 10px 0;

    > li > a {
      font-size: 20px;
    }
  }
}


/**        ¬ level push         **/
.panel-list-level {
  position: absolute;
  top: -50px;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  transform: translate3d(-100%, 0, 0);
  background-color: #fff;
  z-index: 1;
}


/* transitions and open class */
.panel-list-current > .panel-list-item {
  transform: translate3d(0 ,0 ,0);
}

.panel-list-item,
.panel-list-level {
  transition-delay: 0s;
  transition-property: transform;
  transition-timing-function: ease;
  transition-duration: 400ms;

  &.open {
    transition-delay: 0s;
    transition-duration: 400ms;
    transition-property: transform;
    transition-timing-function: ease;
  }
}

.panel-list-item {
  span {
    @include transition--all();
  }
}

/* level open */
.panel-menu-content.open {
  .icon-back {
    display: inline-block;
    left: 20px;
    opacity: 1;
  }

  .panel-list-current > .panel-list-item {
    transform: translate3d(100% ,0 ,0);
  }
}

.panel-list-level.open {
  transform: translate3d(0, 0, 10px);
}