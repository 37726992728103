@charset "UTF-8";
/*************************************

    [TABLE OF CONTENTS]

    1.General settings:

      1.1 GENERAL
      1.2 TYPOGRAPHY
      1.3 CUSTOM GRID

    2.Pages, layouts:

      1.1 CUSTOM NAVBAR
      1.2 SECTION SETTINGS
      1.3 INTRO PAGES
      1.4 HOME PAGEs
      1.5 BLOG LIST, BLOG SINGLE POST
      1.6 SIDEBAR
      1.7 PAGE SERVICES, PAGE SERVICES-2
      1.8 GALLERIES
      1.9 ROOMs
      1.10 ROOM DETAILS
      1.11 ABOUT PAGE
      1.12 PAGE FOOTER

    3.Components & modifiers:

      1.1 Breadcrumbs for inner pages
      1.2 Custom buttons
      1.3 Transitions, animations, states
      1.4 Decorative elements
      1.5 Datepicker for Bootstrap
      1.6 CUSTOM FORM
      1.7 Contact elements
      1.8 Control carousel, pagination, tabs, video, lighbox
      1.9 SECTION PANELS
      1.10 Mobile menu Slideout.js

    Universal styles

*************************************/
/*************************************

    1.General settings:

                                   */
/*************************************

    GENERAL

*************************************/
html {
  font-size: 10px;
  color: #222;
}

body {
  width: 100%;
  height: 100%;
  font-size: 1.6rem;
}

@media (max-width: 479px) {
  body {
    font-size: 1.56rem;
  }
}

@media (min-width: 1200px) {
  body {
    font-size: 1.8rem;
  }
}

main.page-content {
  position: relative;
  background-color: #fff;
  z-index: 1;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

.form-control::-moz-placeholder {
  text-transform: uppercase;
}

.form-control:-ms-input-placeholder {
  text-transform: uppercase;
}

.form-control::-webkit-input-placeholder {
  text-transform: uppercase;
}

button {
  display: inline-block;
  background-color: transparent;
  border: none;
  outline: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

img, svg, .svg {
  vertical-align: middle;
}

a {
  display: inline-block;
  font-size: 14px;
  color: #333333;
  font-family: 'Lato', sans-serif;
  text-decoration: none;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.page-header {
  padding-bottom: 0;
  margin: 0;
  border-bottom: none;
}

label, .label-control {
  display: block;
  margin-bottom: 10px;
  font-family: lato, sans-serif;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  color: #888888;
}

.form-control:focus {
  box-shadow: 0 0 0 transparent;
  border-color: #000;
}

/*************************************

    TYPOGRAPHY

*************************************/
h1, .h1, h2, h3, .h3, h5, h6 {
  color: #000;
}

h1 small,
h1 .small, .h1 small,
.h1 .small, h2 small,
h2 .small, h3 small,
h3 .small, .h3 small,
.h3 .small, h5 small,
h5 .small, h6 small,
h6 .small {
  font-weight: normal;
  line-height: 1;
  color: #888;
}

.small {
  font-family: "Playfair Display", Georgia, "Times New Roman", Times, serif;
  font-size: 1em;
  line-height: 1;
}

h1, .h1 {
  font-size: 2.66667em;
  margin-bottom: 0.625em;
}

h1, .h1, h3, .h3 {
  font-weight: 700;
}

h2, .h2 {
  font-size: 1.66667em;
  line-height: 1.6em;
  margin-top: 0;
  margin-bottom: 1em;
}

h3, .h3 {
  margin: 0;
  font-family: Georgia, "Times New Roman", Times, serif;
  line-height: 1.66667em;
}

h4, .h4 {
  font-family: Lato, "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.section-header {
  margin-bottom: 1.66667em;
}

.section-header a {
  font-size: 1em;
}

.section-header h2 {
  margin: 0;
}

label, .label-control {
  letter-spacing: 0.2em;
}

p, .list-type ul {
  line-height: 1.66667em;
  margin-bottom: 0.72222em;
}

.list-type ul {
  text-align: left;
}

p a {
  font-family: inherit;
}

mark {
  padding: 0;
  color: #fff;
  background-color: #c6aa28;
}

figcaption {
  font-family: Georgia, "Times New Roman", Times, serif;
}

.list-type {
  position: relative;
  padding-left: 0.94444em;
  margin-left: -17px;
}

.list-type li:before {
  content: ' ';
  position: absolute;
  left: 0;
  display: block;
  height: 1em;
  width: 1em;
  margin-top: 1.57143em;
  line-height: 1;
  font-size: 0.38889em;
  background-color: #c6aa28;
  transform: rotate(-45deg);
}

.link-more {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 1em;
  line-height: 1;
  vertical-align: middle;
  color: #c6aa28;
  padding-bottom: 5px;
  border-bottom: 1px solid #c6aa28;
}

.link-more .fa {
  text-align: right;
  font-size: 0.94444em;
  margin-left: 8px;
  vertical-align: bottom;
}

blockquote {
  padding: 0;
  position: relative;
  font-style: italic;
  font-size: 1.22222em;
  line-height: 1.63636em;
  padding-left: 2.18182em;
  margin-left: -48px;
  border: none;
  text-align: left;
  color: #000;
}

blockquote p {
  margin-bottom: 0;
  font-size: 1em;
}

blockquote p:before {
  content: '“';
  font-family: "Playfair Display", Georgia, "Times New Roman", Times, serif;
  position: absolute;
  font-size: 4.77273em;
  left: 0;
  top: -0.3619em;
  line-height: 1em;
  font-style: normal;
  display: inline-block;
  color: #c6aa28;
}

blockquote footer {
  text-align: right;
}

blockquote footer cite {
  font-style: normal;
}

blockquote footer:before {
  content: '\00A0 \2014';
}

@media (max-width: 379px) {
  blockquote {
    font-size: 1em;
  }
}

@media (max-width: 991px) {
  blockquote,
  .list-type {
    margin-left: 0;
  }
}

h1.title-hidden {
  display: none;
}

.intro-slider .intro-hero .promo-title, .intro-slider .intro-hero .cost-disable, .spa-banner span.disabled, .pricing-panel__heading-rw .disabled, .gallery-filter__item > button, .room-hero__top, .room-service .table td.price {
  font-family: Lato, "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #c6aa28;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-size: 1em;
  line-height: 1.66667em;
  vertical-align: middle;
}

.spa-menu__cost, .pricing-panel__main li span, .services-menu__list li span:last-child, .sv-info-panel .cost, .about-story__name small, .about-team__type, .contacts-list [href^=tel],
.contacts-list [href^=mailto],
.contacts-list .address {
  font-family: Lato, "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #c6aa28;
  text-transform: uppercase;
  font-size: 0.77778em;
  font-weight: 700;
  line-height: 2.14286em;
  letter-spacing: 0.2em;
  vertical-align: middle;
}

.intro-slider .intro-hero .cost, .spa-banner__sale, .pricing-panel__heading-rw .cost, .room-banner__cost, .form-res .number-of-reversed .br-current-rating {
  font-family: Lato, "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #c6aa28;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-size: 1.66667em;
  line-height: 1em;
  vertical-align: middle;
}

@media (max-width: 379px) {
  .intro-slider .intro-hero .promo-title, .intro-slider .intro-hero .cost-disable, .spa-banner span.disabled, .pricing-panel__heading-rw .disabled, .gallery-filter__item > button, .room-hero__top, .room-service .table td.price,
  .spa-menu__cost,
  .pricing-panel__main li span,
  .services-menu__list li span:last-child,
  .sv-info-panel .cost,
  .about-story__name small,
  .about-team__type,
  .contacts-list [href^=tel],
  .contacts-list [href^=mailto],
  .contacts-list .address,
  .intro-slider .intro-hero .cost,
  .spa-banner__sale,
  .pricing-panel__heading-rw .cost,
  .room-banner__cost,
  .form-res .number-of-reversed .br-current-rating {
    letter-spacing: 0.08em;
  }
}

/*************************************

    CUSTOM GRID

    width < 768px
    ¬ col-xs
    ¬ item-sm
    ¬ full-wd

    width < 480px
    ¬ col-rs - reset float, width 100%

*************************************/
/**        ¬ clearfix for fix float        **/
.cf:before, .cf:after {
  content: " ";
  display: table;
}

.cf:after {
  clear: both;
}

/**        width < 768px         **/
@media (max-width: 767px) {
  /**         center column, max-width: 480px;        **/
  .col-xs {
    width: 100%;
    max-width: 460px;
    margin: 0 auto;
  }
  /**         gutter bottom: 30px        **/
  .item-sm {
    margin-bottom: 30px;
  }
  /**         gutter width reset        **/
  .full-wd {
    padding: 0;
  }
  .full-wd .row {
    padding: 0;
  }
  .full-wd .row [class*=col] {
    padding: 0;
  }
}

@media (max-width: 479px) {
  /**         reset column         **/
  .col-rs {
    float: none;
    width: 100%;
  }
}

/*************************************

    2.Pages, layouts:

                                   */
/*************************************

    CUSTOM NAVBAR

    = page-header
    = navbar
      ¬ navbar-fixed  - page-header-fixed modifier
      ¬ menu-trigger
      ¬ brand
      ¬ logo
      ¬ social-list
      ¬ reservation
      ¬ dropdown-menu

    [states and dropdown-menu]
    [affix - transform menu]

*************************************/
.navbar-fixed,
.page-header {
  width: 100%;
}

.page-header {
  min-height: 77px;
}

@media (min-width: 768px) {
  .page-header {
    min-height: 156px;
  }
}

@media (max-width: 767px) {
  .page-header {
    position: absolute;
    top: 0;
    left: 0;
  }
}

@media (min-width: 1600px) {
  .page-header {
    min-height: 115px;
  }
}

.navbar {
  left: 0;
  right: 0;
  margin: 0;
  text-transform: uppercase;
  border: none;
  border-radius: 0;
  z-index: 1030;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.3), 0px 8px 10px 1px rgba(0, 0, 0, 0.21), 0px 3px 14px 2px rgba(0, 0, 0, 0.18);
}

.navbar.navbar-fixed {
  position: fixed;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.3), 0px 2px 2px 0px rgba(0, 0, 0, 0.21), 0px 1px 5px 0px rgba(0, 0, 0, 0.18);
}

@media (min-width: 768px) {
  .navbar.navbar-fixed {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.3), 0px 8px 10px 1px rgba(0, 0, 0, 0.21), 0px 3px 14px 2px rgba(0, 0, 0, 0.18);
  }
}

.navbar-header {
  float: none;
  left: 0;
}

.navbar .menu-trigger {
  margin: 24px 0;
}

.navbar-brand {
  height: auto;
  display: block;
  padding: 0;
  border-right: 1px solid transparent;
  z-index: 1000;
}

.navbar > .container .navbar-brand {
  margin-left: 0;
}

.navbar-brand .logo {
  height: 47px;
  width: 47px;
  display: inline-block;
  margin-top: 15.5px;
  margin-bottom: 15.5px;
}

.navbar .social-list {
  padding-left: 25px;
  border-left: 1px solid transparent;
}

.navbar .social-list > li > a {
  margin-top: 21px;
  margin-bottom: 21px;
}

.navbar a:focus {
  color: #ccc;
}

.navbar__reservation {
  position: absolute;
  top: 0;
  left: 50%;
  min-width: 220px;
  margin: 0;
  margin-left: -110px;
  text-align: center;
  letter-spacing: 0.2em;
}

.navbar__reservation > li {
  float: none;
  display: inline-block;
}

.navbar__reservation > li > a {
  padding: 0;
  margin-top: 29px;
  margin-bottom: 29px;
}

.navbar .navbar__reservation > li > a {
  color: #c6aa28;
}

.navbar__reservation > li > a .fa {
  font-size: 1.11em;
  padding-right: 10px;
  letter-spacing: normal;
}

@media (max-width: 479px) {
  .navbar__reservation {
    min-width: 150px;
    margin-left: -75px;
  }
  .navbar__reservation > li > a {
    font-size: 11px;
  }
}

/**        [states and dropdown-menu]         **/
.navbar-menu li a {
  letter-spacing: 0.2em;
}

.navbar-menu > li {
  margin-right: 30px;
}

.navbar-menu > li:last-child {
  margin-right: 0;
}

.navbar-menu > li:last-child .dropdown-menu {
  left: auto;
  right: -100%;
  z-index: 1001;
}

.navbar-menu > li > a {
  padding: 0;
  position: relative;
  display: inline-block;
  color: #333333;
  padding-top: 29px;
  padding-bottom: 29px;
  z-index: 1001;
}

.navbar-menu > li > a > span:first-child:after {
  position: absolute;
  display: inline-block;
  content: '';
  bottom: -1px;
  transform: translateY(-9px);
  opacity: 0;
  left: 0;
  width: 97%;
  height: 0;
  background-color: #c6aa28;
  transition: height 0.26s, opacity 0.26s, transform 0.26s;
}

.navbar-menu > li > a .fa-caret-down {
  font-size: 11px;
  margin-left: 4px;
}

.navbar-menu .dropdown-menu {
  padding: 0 15px;
  min-width: 165px;
  display: block;
  top: 116%;
  visibility: hidden;
  opacity: 0;
  border: none;
  transition: top 0.3s ease 0s, visibility 0.2s ease 0.2s, opacity 0.2s ease 0.1s;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
}

.navbar-menu .dropdown-menu a {
  font-size: 14px;
}

.navbar-menu .dropdown-menu > li > a {
  padding: 15px 0;
  border-bottom: 1px solid #dedede;
  font-weight: 700;
  line-height: 1;
}

.navbar-menu .dropdown-menu > li > a:hover, .navbar-menu .dropdown-menu > li > a:focus, .navbar-menu .dropdown-menu > li > a:active, .navbar-menu .dropdown-menu > li > a.active {
  color: #000;
}

.navbar-menu .dropdown-menu li:last-child > a {
  border-bottom-color: transparent;
}

.navbar-menu > li:hover .dropdown-menu {
  visibility: visible;
  top: 100%;
  opacity: 1;
  transition: top 0.29s ease 0s;
}

.navbar-menu > li:hover > a > span:first-child:after {
  bottom: -1px;
  height: 4px;
  opacity: 1;
  transform: translateY(0);
}

@media (min-width: 992px) {
  .navbar-menu > li {
    margin-right: 50px;
  }
}

@media (max-width: 1599px) {
  .navbar__topline {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
  .navbar-menu {
    float: none;
    font-size: 0;
    text-align: center;
  }
  .navbar-menu > li {
    float: none;
    display: inline-block;
  }
}

@media (min-width: 1600px) {
  .navbar__topline {
    position: absolute;
    width: 100%;
    border-bottom-color: transparent;
  }
  .navbar-header {
    padding: 0 50px;
    width: auto;
    margin: 0;
  }
  .navbar-nav a {
    font-size: 18px;
  }
  .navbar-brand {
    padding-right: 50px;
    border-right: 1px solid #dedede;
  }
  .navbar-brand .svg {
    margin-top: 34px;
    margin-bottom: 34px;
  }
  .navbar .social-list {
    padding-left: 50px;
    border-left: 1px solid #dedede;
  }
  .navbar .social-list > li > a {
    margin-top: 39.5px;
    margin-bottom: 39.5px;
  }
  .navbar-menu > li > a {
    padding-top: 47.5px;
    padding-bottom: 47.5px;
  }
  .navbar__reservation {
    position: static;
    float: right;
    text-align: right;
    margin-left: 0;
    font-size: 14px;
    line-height: 20px;
    vertical-align: middle;
  }
  .navbar__reservation > li > a {
    margin-top: 47.5px;
    margin-bottom: 47.5px;
  }
}

/**        [affix - transform menu]         **/
.affix {
  background-color: #fff;
}

.affix .navbar__topline {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.affix .navbar-brand .svg {
  height: 33px;
  width: 33px;
  margin: 12px 0;
}

.affix .social-list > li > a {
  margin: 10.5px 0;
}

.affix .navbar-menu > li > a {
  padding: 18.5px 0;
  font-size: 12px;
}

.affix .navbar__reservation > li > a {
  margin: 18.5px 0;
  font-size: 12px;
}

.affix .menu-trigger {
  margin: 13.5px 0;
}

@media (min-width: 768px) {
  .affix {
    top: -57px;
  }
}

@media (min-width: 1600px) {
  .affix {
    top: -2px;
  }
  .affix .navbar-menu > li > a,
  .affix .navbar__reservation > li > a {
    font-size: 15px;
  }
  .affix .navbar-brand .svg {
    margin: 22.5px 0;
  }
  .affix .social-list > li > a {
    margin: 21px 0;
  }
  .affix .navbar-menu > li > a {
    padding: 29px 0;
  }
  .affix .navbar__reservation > li > a {
    margin: 29px 0;
  }
}

.affix:hover {
  top: 0;
}

/*************************************

    SECTION SETTINGS

    = st-row
      ¬ inner
      ¬ section-title
      ¬ heading
      ¬ st-row--hr
      ¬ st-row--gray
      ¬ st-row--bg

    = hr-heading
      ¬ h1
      ¬ small

    = over
    = overlay
    = full-wd

*************************************/
.st-row {
  position: relative;
  padding-bottom: 2.77778em;
  /**        ¬ section no title - add padding-top          **/
}

.st-row h2.section-title {
  margin: 1.26667em 0;
}

@media (max-width: 991px) {
  .st-row__heading {
    margin-bottom: 1.66667em;
  }
  .st-row__inner h1 {
    line-height: 1em;
    margin-bottom: 0.5em;
  }
}

@media (max-width: 479px) {
  .st-row__inner h1 {
    font-size: 1.33333em;
    line-height: 1.25em;
    margin-bottom: 0.625em;
  }
}

.st-row--gray {
  background-color: #fafafa;
}

.st-row--bg {
  background: url("./images/bg-section.png") repeat 0;
}

.st-row--hr {
  padding: 4.44444em 0;
}

/**        ¬ hero section title         **/
.hr-heading {
  position: relative;
  text-align: center;
  margin-top: 4.16667em;
  margin-bottom: 2.77778em;
}

.hr-heading h1, .hr-heading .h1 {
  position: relative;
  text-align: center;
  font-size: 2.66667em;
  line-height: 1;
  padding-bottom: 0.625em;
  margin-top: 0;
  margin-bottom: 0.625em;
  font-weight: 700;
  border-bottom: 1px solid #c6aa28;
}

.hr-heading h1:after, .hr-heading .h1:after {
  display: block;
  position: absolute;
  content: ' ';
  left: 50%;
  bottom: -5px;
  font-size: 9px;
  height: 9px;
  width: 9px;
  background-color: #c6aa28;
  transform: translateX(-50%);
}

.hr-heading.rotate h1:after, .hr-heading .h1:after {
  transform: translateX(-50%) rotate(45deg);
}

.hr-heading small {
  display: inline-block;
  width: 100%;
  font-family: Lato, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1em;
  color: #888;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

@media (max-width: 991px) {
  .hr-heading {
    margin-bottom: 1.66667em;
  }
}

/**        = overlay         **/
.over {
  background-color: rgba(0, 0, 0, 0.7);
}

.over--light {
  background-color: rgba(255, 255, 255, 0.6);
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  transition: all 0.4s ease;
}

.overlay--light {
  background-color: rgba(255, 255, 255, 0.6);
}

/*************************************

    INTRO PAGES

    = page-home
      ¬ extra-title
      ¬ intro-banner

    = page-inner
      ¬ intro-banner

    = intro-slider
      ¬ swiper-slider
      ¬ intro-hero
        ¬ price

      ¬ promo-title
      ¬ intro-title
      ¬ intro-title--decor
      ¬ list-type
      ¬ control-panel

    = PAGE 3 intro-video

    SETTINGS background and parallax
    = intro-bg

*************************************/
.page-home .intro-banner {
  text-align: center;
}

.page-home .intro-banner .intro-video {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.page-home .intro-banner__inner {
  height: 720px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
}

@media (max-width: 767px) {
  .page-home .intro-banner__inner {
    padding-top: 78px;
    height: 115vh;
  }
}

@media (max-width: 589px) {
  .page-home .intro-banner__inner {
    height: 100vh;
  }
}

.extra-title {
  flex-basis: 420px;
  color: #fff;
}

.extra-title__top {
  font-family: Lato, "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #c6aa28;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

.extra-title h1 {
  font-size: 5.11111em;
  margin: 0.05435em 0 0.32609em;
  color: inherit;
  white-space: nowrap;
}

.extra-title span.small {
  display: inline-block;
  font-size: 1.66667em;
  line-height: 1.2em;
  margin-top: 1.56667em;
}

@media (max-width: 767px) {
  .extra-title {
    max-width: 320px;
  }
  .extra-title h1 {
    font-size: 64px;
  }
}

@media (max-width: 319px) {
  .extra-title {
    max-width: 280px;
  }
  .extra-title h1 {
    font-size: 3.11111em;
  }
  .extra-title span.small {
    font-size: 1.33333em;
    line-height: 1.5em;
    margin-top: 1.95833em;
  }
}

.page-inner .intro-banner {
  text-align: center;
}

.page-inner .intro-banner__inner {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 7.66667em 0 6.27778em;
}

@media (max-width: 767px) {
  .page-inner .intro-banner__inner {
    padding: 12em 0 6.27778em;
  }
}

.page-inner .intro-banner .title {
  color: #fff;
  font-size: 2.66667em;
  margin: 0.10417em 0 0.625em;
}

.intro-banner__inner {
  position: relative;
}

/**        PAGE 2 = intro-slider         **/
.intro-slider {
  height: 720px;
  min-height: 590px;
}

.intro-slider .swiper-slide {
  position: relative;
  background-position: 50% 25%;
  background-size: cover;
}

.intro-slider .swiper-slide__inner {
  position: relative;
  height: 100%;
}

.intro-slider .swiper-slide:after {
  position: absolute;
  content: ' ';
  display: block;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.intro-slider .intro-hero {
  position: relative;
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  height: 100%;
  justify-content: center;
  color: #fff;
  z-index: 2;
}

.intro-slider .intro-hero__price {
  margin-bottom: 20px;
}

.intro-slider .intro-hero__price > *:first-child {
  margin-right: 10px;
}

.intro-slider .intro-hero p, .intro-slider .intro-hero .list-type {
  margin-bottom: 13px;
}

.intro-slider .intro-hero p {
  max-width: 255px;
}

.intro-slider .intro-hero--center {
  max-width: 557px;
  flex-basis: 557px;
  margin: 0 auto;
  text-align: center;
}

.intro-slider .intro-hero--center p {
  max-width: none;
}

.intro-slider .intro-hero .intro-title {
  position: relative;
  color: #fff;
  margin-top: 0;
  margin-bottom: 0.625em;
}

.intro-slider .intro-hero .intro-title__text {
  display: inline-block;
  margin-bottom: 0.27083em;
}

.intro-slider .intro-hero .intro-title__text.under {
  border-bottom: 1px solid #c6aa28;
  padding-bottom: 0.27083em;
  margin-bottom: 0;
}

.intro-slider .intro-hero .intro-title .promo-title {
  display: block;
  font-size: 37.5%;
  line-height: 1;
  font-weight: 400;
  margin-bottom: 0.72222em;
}

.intro-slider .intro-hero .intro-title--decor {
  padding-bottom: 0.52083em;
  margin-bottom: 0.52083em;
  border-bottom: 1px solid #c6aa28;
}

.intro-slider .intro-hero .intro-title--decor:after {
  position: absolute;
  left: 50%;
  bottom: -5px;
  font-size: 10px;
  margin-left: -5px;
}

.intro-slider .intro-hero .flag {
  margin-left: 30px;
  vertical-align: middle;
}

.intro-slider .intro-hero .list-type ul > li {
  margin-bottom: 7px;
}

.intro-slider .intro-hero .cost {
  letter-spacing: normal;
}

.intro-slider .intro-hero .cost-disable {
  color: #888;
  letter-spacing: normal;
  text-decoration: line-through;
}

.intro-slider .intro-hero .btn {
  margin-top: 2.14286em;
}

.intro-slider .control-panel__wrapper {
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  z-index: 2;
}

.intro-slider .control-panel__nav {
  transform: translateY(0);
}

.intro-slider .control-panel__dots {
  position: static;
}

@media (max-width: 767px) {
  .intro-slider {
    height: 100vh;
  }
}

@media (max-width: 479px) {
  .intro-slider .intro-hero {
    text-align: center;
  }
  .intro-slider .intro-hero p {
    margin: 0 auto;
  }
  .intro-slider .intro-hero .flag {
    display: block;
    margin: 15px auto 0;
  }
}

/**        settings background and parallax effect         **/
.intro-bg {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  z-index: 1;
}

/*************************************

    HOME PAGEs

    = hm-features
    = hm-features-2
    = hm-about
    = spa-menu
      ¬ spa-banner

    = hm-stat
    = our-clients = testimonials
      ¬ blockquote
      ¬ control-panel
    = hm-gallery-2

*************************************/
.hm-features {
  text-align: center;
  font-size: 8px;
}

.hm-features__row {
  padding: 35px 0;
  margin-left: -16px;
}

.hm-features__item {
  padding: 1.28571em 0 0.92857em;
  margin: 15px 0;
  border-left: 1px solid #e0e0e0;
}

.hm-features__icon {
  min-height: 88px;
  padding-bottom: 24px;
  text-align: center;
}

.hm-features__icon .svg {
  display: inline-block;
  height: 64px;
  fill: #c6aa28;
}

.hm-features span.h2 {
  font-size: 2.14286em;
}

@media (max-width: 319px) {
  .hm-features__item {
    width: 100%;
    margin: 10px 0;
    padding: 0 15px;
  }
}

@media (min-width: 480px) {
  .hm-features {
    font-size: 10px;
  }
}

@media (min-width: 992px) {
  .hm-features {
    font-size: 12px;
  }
  .hm-features__row {
    max-width: 1820px;
    margin: 0 auto;
  }
  .hm-features__item {
    max-width: 385px;
    width: 20%;
  }
  .hm-features__item:first-child {
    border-left-width: 0;
  }
}

@media (min-width: 1440px) {
  .hm-features {
    font-size: 14px;
  }
}

/**        = hm features-2         **/
.hm-features-2 {
  text-align: center;
}

.hm-features-2 .item {
  margin-bottom: 1.66667em;
}

.hm-features-2__item {
  width: 100%;
  max-width: 360px;
  padding: 45px 11.11111% 32px;
  margin: 0 auto;
  background-color: #fafafa;
  border: 1px solid #ccc;
  border-radius: 0px 0px 2px 2px;
}

.hm-features-2__icon {
  min-height: 80px;
  padding-bottom: 25px;
  line-height: 0;
}

.hm-features-2__icon [class^=pe-7s] {
  color: #c6aa28;
  font-size: 55px;
  line-height: 1;
}

.hm-features-2__icon img {
  display: inline-block;
  margin: 0 auto;
}

.hm-features-2 span.h2 {
  line-height: 1;
}

.hm-features-2 span.h2:after {
  margin: 14px auto 12px;
}

@media (max-width: 991px) {
  .hm-features-2__item {
    padding: 45px 5%;
  }
}

@media (max-width: 554px) {
  .hm-features-2 .item {
    float: none;
    width: 100%;
  }
}

.hm-about__main {
  background-color: #fff;
  padding: 50px 65px 50px 50px;
}

.hm-about__heading {
  margin-bottom: 20px;
}

.hm-about__heading h2 {
  display: inline-block;
  margin-bottom: 0;
  line-height: 1;
  font-size: 2.66667em;
  font-weight: 700;
}

.hm-about__heading h2:after {
  display: block;
  content: ' ';
  width: 100%;
  height: 1px;
  margin-top: 20px;
  background-color: #c6aa28;
}

.hm-about__btn {
  background-color: #fff;
  margin-top: 44px;
}

.hm-about__list-mode {
  padding: 40px 30px;
  font-family: Lato, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 0.77778em;
  text-transform: uppercase;
  background-color: #fafafa;
}

.hm-about__list-mode > li {
  padding: 0.42857em 0;
}

.hm-about__list-mode > li:nth-last-child(n+2) {
  border-bottom: 1px solid #dedede;
}

.hm-about__list-mode > li > span {
  display: inline-block;
  width: 50%;
  line-height: 2.14286em;
  letter-spacing: 0.2em;
  color: #888;
}

.hm-about__list-mode > li > span:last-child {
  text-align: right;
}

.hm-about__list-mode > li > span.disabled {
  color: #888;
}

.hm-about__list-mode > li > span:not(.disabled):last-child {
  color: #c6aa28;
}

@media (max-width: 991px) {
  .hm-about__heading {
    text-align: center;
  }
  .hm-about__btn {
    position: relative;
    z-index: 10;
    margin-bottom: 3.16667em;
  }
  .hm-about .btn {
    width: 100%;
  }
}

@media (max-width: 479px) {
  .hm-about__main {
    padding: 50px 20px;
  }
  .hm-about__btn {
    padding: 0 20px;
    margin: 44px -20px 3.16667em;
  }
  .hm-about__list-mode {
    margin: 0 -20px;
  }
  .hm-about__list-mode > li > span {
    letter-spacing: 0.08em;
  }
}

.spa-menu__row {
  margin-bottom: 20px;
}

.spa-menu__item {
  position: relative;
  padding: 1.66667em 0 10px 98px;
  transition: all 0.4s ease;
}

.spa-menu__item:nth-last-of-type(n+2) {
  border-bottom: 1px solid #e0e0e0;
}

.spa-menu__thumb {
  position: absolute;
  top: 30px;
  max-width: 83px;
  margin-left: -98px;
  margin-bottom: 19px;
  transition: all 0.4s ease;
}

.spa-menu__thumb img {
  border-radius: 2px;
}

.spa-menu__heading {
  margin-top: -6px;
}

.spa-menu__cost {
  line-height: 30px;
}

.spa-menu .control-panel {
  margin-bottom: 1.66667em;
}

@media (max-width: 991px) {
  .spa-menu__content .row > [class^=col]:first-child {
    border-bottom: 1px solid #e0e0e0;
  }
}

@media (max-width: 479px) {
  .spa-menu__item {
    padding-left: 0;
  }
  .spa-menu__thumb {
    position: static;
    float: left;
    width: 90px;
    max-width: none;
    margin: 19px 17px 6px 0;
  }
  .spa-menu p {
    margin: 13px 0 0;
  }
}

.spa-banner {
  position: relative;
  margin-top: -1px;
  border-top-right-radius: 8px;
  margin-bottom: 30px;
  background-color: #ccc;
}

.spa-banner img {
  border-top-right-radius: 8px;
}

.spa-banner__bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 25px 9.00901%;
  margin-top: -1px;
  background-color: rgba(0, 0, 0, 0.7);
}

@media (max-width: 479px) {
  .spa-banner__bottom {
    position: static;
    background-color: #333333;
  }
}

.spa-banner__title {
  font-family: "Playfair Display", Georgia, "Times New Roman", Times, serif;
  color: #fff;
  font-size: 1.66667em;
  line-height: 1;
}

.spa-banner span.disabled {
  color: #888;
  text-decoration: line-through;
}

.spa-banner__sale {
  margin-left: 10px;
}

.spa-banner span.disabled, .spa-banner__sale {
  letter-spacing: normal;
}

.hm-stat {
  line-height: 1;
  text-align: center;
  padding: 4.44444em 0 1.66667em;
}

.hm-stat__item {
  margin-bottom: 2.77778em;
}

@media (max-width: 991px) {
  .hm-stat {
    font-size: 14px;
  }
}

.our-clients__wrapper {
  padding: 0;
}

.our-clients__item {
  padding: 0 15px;
}

.our-clients__main {
  position: relative;
  background-color: #fff;
  font-size: 1em;
  border-radius: 2px;
  padding: 24px 30px;
  margin-bottom: 15px;
  transition: all .4s;
}

.our-clients__main:after {
  content: '';
  display: block;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-style: solid;
  height: 0;
  width: 0;
  border-color: #fff transparent transparent;
  border-width: 0.7rem 0.7rem 0;
}

.our-clients__main blockquote {
  font-size: 1em;
  padding-left: 48px;
  margin: 0;
  margin-left: -48px;
}

.our-clients__main blockquote p:before {
  font-size: 105px;
}

.our-clients__main blockquote footer {
  font-size: 100%;
  line-height: 1.66667em;
}

.our-clients__thumb {
  display: block;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  background-color: #ccc;
  border: 2px solid #c6aa28;
  border-radius: 50%;
  overflow: hidden;
}

.our-clients__thumb img {
  border-radius: 50%;
}

.our-clients .control-panel {
  margin: 3.61111em 15px 0;
}

@media (max-width: 991px) {
  .our-clients__main {
    padding-left: 15px;
    padding-right: 15px;
  }
  .our-clients__main blockquote {
    font-size: 14px;
  }
  .our-clients__main blockquote p:before {
    left: 15px;
  }
}

/**        ¬ hm-gallery-2         **/
.hm-gallery-2 .panels-wrapper {
  min-height: auto;
  padding-bottom: 72px;
}

.hm-gallery-2 .panel-board {
  padding-bottom: 0;
}

.hm-gallery-2__header {
  padding: 2.77778em;
  text-align: center;
  background-color: #fafafa;
}

.hm-gallery-2__header h1, .hm-gallery-2__header .h1 {
  margin: 0;
}

.hm-gallery-2__slides {
  padding: 0;
}

.hm-gallery-2__slides-inner {
  max-width: 960px;
  margin-left: -8px;
  float: right;
}

.hm-gallery-2__item {
  float: left;
  padding: 0;
  padding-left: 8px;
  padding-bottom: 8px;
}

@media (max-width: 767px) {
  .hm-gallery-2__header h1, .hm-gallery-2__header .h1 {
    display: inline-block !important;
  }
  .hm-gallery-2 .panel-board {
    text-align: left;
    padding-top: 40px;
  }
}

/*************************************

    BLOG LIST, BLOG SINGLE POST

      = blog-post
      = post
        ¬ thumbnail
        ¬ video
        ¬ blog-post-navigation
        ¬ pagination
        ¬ main-post
        ¬ post-gallery
        ¬ section-comments
        ¬ post-comment
        ¬ blog-post-other
        ¬ date
        ¬ post-meta
        ¬ author-post
        ¬ meta-count

      = post-share
      = social-list

*************************************/
/**        = wrapper content and sidebar         **/
.blog-post__wrapper {
  padding-bottom: 80px;
}

.blog-post-row {
  margin-top: -10px;
}

/**        = blog-post         **/
.blog-post {
  position: relative;
  border-bottom: 1px solid #dedede;
}

.blog-post .section-header h2:hover {
  color: #ccc;
}

.blog-post .section-header h2:focus, .blog-post .section-header h2:active {
  color: #333333;
}

.blog-post-item {
  margin-bottom: 2em;
}

.blog-post-item:last-child {
  border-bottom-color: transparent;
}

.blog-post-item p {
  margin-bottom: 2.16667em;
}

.blog-post-inner {
  margin-bottom: 4.44444em;
}

.blog-post__meta {
  position: relative;
  top: 0;
  left: 0;
  text-align: right;
}

.blog-post__meta > li {
  margin-bottom: 6px;
  line-height: 30px;
  vertical-align: middle;
}

.blog-post__meta .post-meta > span {
  margin-left: 15px;
}

.blog-post__thumbnail {
  display: block;
}

.blog-post__thumbnail img {
  width: 100%;
  margin-top: 6px;
  margin-bottom: 24px;
}

.blog-post__video .video-contain {
  height: auto;
  padding: 0;
}

.blog-post__video .video-contain__preview {
  position: relative;
}

.blog-post__video .video-contain__ratio {
  height: 0;
  padding-bottom: 56.25%;
}

@media (min-width: 1200px) {
  .blog-post {
    padding-left: 190px;
  }
  .blog-post__meta {
    position: absolute;
    top: 10px;
  }
}

@media (max-width: 1199px) {
  .blog-post__meta {
    margin-bottom: 30px;
  }
  .blog-post__meta > li {
    float: right;
    margin-left: 15px;
  }
}

/**        = blog-post pagination         **/
@media (max-width: 991px) {
  .blog-post-navigation {
    margin-bottom: 4.44444em;
  }
}

.pagination {
  width: 100%;
  min-height: 80px;
  padding: 19px 0;
  margin: 0;
  text-transform: uppercase;
  text-align: center;
  font-size: 0;
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
}

.pagination > li {
  display: inline-block;
  vertical-align: middle;
}

.pagination > li > a {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  padding: 6px 17px;
}

.pagination > li > a:focus {
  color: #888;
}

.pagination > li:first-child {
  float: left;
}

.pagination > li:last-child {
  float: right;
}

.pagination > li:first-child > a,
.pagination > li:last-child > a {
  padding-left: 0;
  padding-right: 0;
}

.pagination .fa {
  font-size: 24px;
  line-height: 1;
  padding: 0;
}

@media (max-width: 379px) {
  .pagination > li > a {
    font-size: 14px;
    padding: 6px 10px;
  }
}

.main-post {
  padding-bottom: 1.66667em;
}

.main-post p a.link {
  font-size: inherit;
  line-height: 1.4;
}

.main-post:last-child {
  border-bottom-width: 0;
}

/**        = post-gallery         **/
.post-gallery {
  margin-top: 22px;
}

.post-gallery__row {
  position: relative;
  margin: 0 -4px;
}

.post-gallery__item {
  float: left;
  display: block;
  width: 50%;
  padding: 0 4px;
  margin-bottom: 8px;
  overflow: hidden;
}

.post-gallery__item img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

.post-gallery__figcaption {
  text-align: center;
  font-size: 14px;
}

/**        = post-share         **/
.post-share .social-list {
  float: left;
  margin-left: -145px;
  width: 115px;
  text-align: right;
}

.post-share .social-list:before {
  display: block;
  content: 'Share post';
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 4px;
  margin-right: 6px;
}

.post-share .social-list > li {
  margin-bottom: 6px;
}

.post-share .social-list > li:nth-child(3n) {
  margin-right: 0;
}

@media (max-width: 1199px) {
  .post-share .social-list {
    float: none;
    width: auto;
    margin-left: 0;
    margin-bottom: 24px;
    margin-top: -30px;
  }
  .post-share .social-list > li {
    margin-right: 0;
    margin-left: 14px;
  }
}

@media (max-width: 479px) {
  .post-share .social-list {
    text-align: center;
  }
  .post-share .social-list > li {
    margin: 0 3px 6px;
  }
}

@media (min-width: 1200px) {
  .post-share__descr {
    float: right;
  }
}

/**        = comments         **/
.section-comments .section-title {
  font-weight: 400;
  line-height: 1;
  margin-bottom: 50px;
}

@media (max-width: 991px) {
  .section-comments {
    margin-bottom: 4.44444em;
  }
}

.section-comments__wrapper {
  margin-bottom: 62px;
}

.section-comments__wrapper > .post-comment {
  padding-top: 18px;
  border-top: 1px solid #dedede;
}

.section-comments__wrapper > .post-comment:nth-of-type(1) {
  border-top-width: 0;
}

.section-comments__wrapper > .post-comment .post-comment {
  border-top: 1px solid #dedede;
}

.section-comments__wrapper > .post-comment .post-comment {
  padding-top: 18px;
}

.post-comment [class*=col] {
  transition: all 0.4s ease;
}

.post-comment__col-l, .post-comment__col-l2 {
  max-width: 105px;
  float: left;
}

.post-comment__col-r, .post-comment__col-r2 {
  padding-left: 125px;
}

@media (max-width: 479px) {
  .post-comment__col-l, .post-comment__col-l2 {
    width: 20%;
    max-width: none;
    padding-right: 2%;
  }
  .post-comment__col-r, .post-comment__col-r2 {
    width: 80%;
    padding: 0;
    float: left;
  }
}

.post-comment__heading > * {
  line-height: inherit;
  vertical-align: middle;
  font-weight: 700;
}

.post-comment__avatar {
  max-width: 105px;
}

.post-comment__avatar img {
  border-radius: 50%;
}

.post-comment__title {
  display: inline-block;
  margin-right: 11px;
}

.post-comment__date, .post-comment__reply {
  display: inline-block;
  text-transform: uppercase;
  font-size: 14px;
}

.post-comment__date {
  font-family: Lato, sans-serif;
  letter-spacing: 0.2em;
  color: #888;
  margin-right: 26px;
}

.post-comment__reply {
  color: #c6aa28;
  letter-spacing: 0.2em;
}

.post-comment__reply .fa {
  margin-right: 8px;
}

.post-comment__reply:hover {
  color: #ccc;
}

.post-comment__reply:focus {
  color: #000;
}

.post-comment__avatar, .post-comment__text-wrap {
  margin-bottom: 18px;
}

@media (max-width: 767px) {
  .post-comment p {
    font-size: 0.88889em;
  }
}

@media (max-width: 479px) {
  .post-comment .col-xs {
    padding: 0;
    left: 10px;
  }
  .post-comment .col-xs > figure {
    max-width: 45px;
  }
}

/**        = blog-post-other         **/
.blog-post-other {
  position: relative;
  padding: 17px 55px;
  margin-bottom: 4.44444em;
  background-color: #fafafa;
  border-radius: 2px;
}

.blog-post-other__more {
  padding-top: 7px;
  padding-bottom: 7px;
  color: #c6aa28;
  font-size: 1em;
}

.blog-post-other__more:nth-child(2n) {
  float: right;
  text-align: right;
}

.blog-post-other__descr {
  display: inline-block;
  max-width: 230px;
  margin: 0;
  font-family: Georgia, "Times New Roman", Times, serif;
  vertical-align: middle;
}

.blog-post-other .fa {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 55px;
  font-size: 26px;
}

.blog-post-other .fa::before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.blog-post-other [class*=left] {
  left: 0;
}

.blog-post-other [class*=left]::before {
  right: 15px;
}

.blog-post-other [class*=right] {
  right: 0;
}

.blog-post-other [class*=right]::before {
  left: 15px;
}

.blog-post-other p, .blog-post-other .fa, .blog-post-other .fa::before {
  transition: margin .2s ease;
}

@media (max-width: 479px) {
  .blog-post-other p {
    text-align: center;
    max-width: none;
  }
}

@media (max-width: 379px) {
  .blog-post-other {
    padding: 24px;
  }
  .blog-post-other .fa {
    width: 24px;
  }
  .blog-post-other [class*=left]::before {
    right: 0;
  }
  .blog-post-other [class*=right]::before {
    left: 0;
  }
}

/**        = blog-post - meta information         **/
.date {
  text-transform: uppercase;
}

.post-meta {
  text-transform: uppercase;
  font-weight: 700;
  color: #888;
}

.post-meta a,
.post-meta span {
  color: #888;
  letter-spacing: 0.2em;
}

.post-meta span {
  display: inline-block;
  font-size: 14px;
  font-family: lato, sans-serif;
}

.post-meta .fa {
  margin-right: 4px;
}

.author-post {
  font-weight: 400;
  line-height: 1;
  color: #888;
}

.meta-count {
  color: #888;
}

/*************************************

    SIDEBAR

    = blog-sidebar
      ¬ __subscribe
      ¬ __category
      ¬ __title

    = categories-list
    = sidebar-preview
    = post-meta
    = sidebar-thumb

*************************************/
.blog-sidebar {
  background-color: #fafafa;
  padding: 50px 11.45038% 14px;
  border: 1px solid #dedede;
  font-size: 14px;
  color: #000;
}

.blog-sidebar .btn {
  width: 100%;
  padding: 15px 0;
}

.blog-sidebar a {
  color: #c6aa28;
}

.blog-sidebar a:hover {
  color: #ccc;
}

.blog-sidebar a:focus {
  color: #000;
}

.blog-sidebar__subscribe {
  margin-bottom: 50px;
}

.blog-sidebar__category {
  margin-bottom: 36px;
}

.blog-sidebar p {
  line-height: 1.43em;
  margin-bottom: 1.57em;
}

.blog-sidebar__title {
  margin-top: 0;
  margin-bottom: 25px;
  font-family: 'Lato', sans-serif;
  line-height: 1;
}

@media (max-width: 479px) {
  .blog-sidebar {
    margin: 0 -15px;
    border-width: 1px 0;
  }
}

.categories-list:before, .categories-list:after {
  content: " ";
  display: table;
}

.categories-list:after {
  clear: both;
}

.categories-list > li a {
  display: block;
  padding: 11px 0;
  border-bottom: 1px solid #dedede;
  transition: none;
}

.categories-list > li a span {
  transition: all 0.4s ease;
}

.categories-list > li a:hover span {
  color: #ccc;
}

.categories-list > li a:focus span {
  color: #000;
}

.categories-list > li:last-child a {
  border-bottom-color: transparent;
}

.categories-list .meta-count {
  float: right;
}

.sidebar-preview > li + li {
  padding-top: 15px;
}

.sidebar-preview > li:nth-last-child(n+2) {
  border-bottom: 1px solid #dedede;
}

.sidebar-preview__content {
  min-height: 57px;
}

.sidebar-preview .post-meta {
  padding: 9px 0 12px;
}

.sidebar-preview .post-meta:before, .sidebar-preview .post-meta:after {
  content: " ";
  display: table;
}

.sidebar-preview .post-meta:after {
  clear: both;
}

.sidebar-preview .post-meta a.comment {
  float: right;
}

.sidebar-preview .post-meta a.date,
.sidebar-preview .post-meta a.comment {
  color: #888;
}

.sidebar-preview .post-meta a.date:hover,
.sidebar-preview .post-meta a.comment:hover {
  color: #ccc;
}

.sidebar-preview .post-meta a.date:focus,
.sidebar-preview .post-meta a.comment:focus {
  color: #333333;
}

.sidebar-thumb {
  position: relative;
  float: left;
  width: 48px;
  margin-top: 3px;
  margin-right: 13px;
  background-color: #ccc;
  border-radius: 2px;
}

.categories-list a,
.blog-tags a {
  font-weight: 700;
}

.blog-sidebar__title,
.categories-list a,
.blog-tags a {
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .categories-list a,
  .blog-tags a {
    letter-spacing: 0.1em;
  }
  .blog-sidebar__title {
    font-size: 16px;
    letter-spacing: 0.04em;
  }
  .blog-sidebar a {
    font-size: 13px;
  }
}

/*************************************

    PAGE SERVICES, PAGE SERVICES-2
      = services-menu

    SERVICES
      = slides
        ¬ services-slide
        ¬ __helper

      = pricing
      = pricing-panel

    SERVICES-2
      = services-panels
      = sv-info-panel

*************************************/
.page-services main {
  margin-bottom: 80px;
}

.page-services-2 .st-row {
  padding-bottom: 4.44444em;
}

.services-slides .st-row {
  padding: 0;
}

/**        = services-slide         **/
.services-slide {
  overflow: hidden;
  cursor: pointer;
}

.services-slide__title {
  margin: 173px 0;
  color: #fff;
  text-align: center;
  opacity: 1;
}

.services-slide__title:before {
  content: ' ';
  display: block;
  font-size: 0.5em;
  height: 1em;
  width: 1em;
  margin: 0 auto 2.77778em;
  background-color: #c6aa28;
  transform: rotate(-45deg);
}

.services-slide__helper {
  position: absolute;
  top: 100%;
  left: 20px;
  right: 20px;
  width: 100%;
  max-width: 650px;
  margin: 0 auto;
  text-align: center;
  transform: translateY(0);
  z-index: 2;
  transition: all 0.4s ease;
}

.services-slide__helper p {
  text-align: left;
}

.services-slide .over {
  transition: all 0.4s ease;
}

.services-slide:after {
  content: ' ';
  position: absolute;
  top: 100%;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 1;
  transition: all 0.4s ease;
}

.services-slide:hover .services-slide__title {
  opacity: 0;
}

.services-slide:hover .services-slide__helper {
  top: 50%;
  transform: translateY(-50%);
}

.services-slide:hover:after {
  top: 0;
}

.services-slide:hover .over {
  background-color: transparent;
}

.pricing {
  padding-bottom: 1.11111em;
}

@media (max-width: 1199px) {
  .pricing {
    text-align: center;
  }
}

/**        = pricing-panel         **/
.pricing-panel {
  position: relative;
  border: 1px solid #dedede;
  background-color: #fafafa;
  padding: 42px 30px 138px;
  margin-bottom: 1.66667em;
  border-radius: 2px;
}

.pricing-panel h2 {
  margin-bottom: 0;
}

.pricing-panel__heading {
  margin-bottom: 9px;
}

.pricing-panel__heading-rw span {
  display: inline-block;
}

.pricing-panel__heading-rw .cost {
  letter-spacing: normal;
  margin-right: 10px;
}

.pricing-panel__heading-rw .disabled {
  color: #888;
  letter-spacing: normal;
}

.pricing-panel__main li {
  padding: 0.72222em 0;
  line-height: 1.66667em;
}

.pricing-panel__main li:nth-last-of-type(n+2) {
  border-bottom: 1px solid #e9e9e9;
}

.pricing-panel__main li span {
  display: block;
}

.pricing-panel__footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 34px 30px 50px;
}

.pricing-panel .flag {
  top: -1px;
  right: -1px;
}

.pricing-panel--hero {
  background-color: #333333;
  border-color: #333333;
  color: #fff;
}

.pricing-panel--hero h2 {
  color: #fff;
}

.pricing-panel--hero ul > li:nth-last-of-type(n+2) {
  border-bottom: 1px solid #676767;
}

@media (max-width: 767px) {
  .pricing-panel {
    text-align: center;
  }
  .pricing-panel__footer .btn {
    width: 100%;
  }
}

/**        = services-menu         **/
.services-menu__inner {
  border: 1px solid #dedede;
  background-color: #fff;
  padding: 42px 30px 24px;
  border-radius: 2px;
}

.services-menu__item {
  margin-bottom: 18px;
}

.services-menu__list {
  display: table;
  width: 100%;
}

.services-menu__list li {
  display: table-row;
}

.services-menu__list li span {
  display: table-cell;
}

.services-menu__list li span:last-child {
  text-align: right;
}

@media (max-width: 479px) {
  .services-menu {
    text-align: center;
  }
  .services-menu__list {
    display: block;
  }
  .services-menu__list li {
    margin-bottom: 18px;
  }
  .services-menu__list li,
  .services-menu__list li span {
    display: block;
  }
  .services-menu__list li span:last-child {
    text-align: center;
  }
}

/**        = PAGE SERVICES-2          **/
.services-panels {
  overflow: hidden;
}

.services-panels .row > [class^=col] {
  height: 400px;
  padding: 0;
}

.services-panels .row > [class^=col] > * {
  height: 100%;
}

.services-panels .img-wrap {
  position: absolute;
  top: 0;
  right: 0;
  width: 960px;
  overflow: hidden;
}

.services-panels .img-wrap img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.services-panels .img-wrap.right {
  right: auto;
}

@media (max-width: 1199px) {
  .services-panels .img-wrap {
    left: 50%;
    right: auto;
    margin-left: -480px;
  }
}

@media (max-width: 991px) {
  .services-panels {
    font-size: 0.94444em;
  }
  .services-panels .row > [class^=col] {
    height: 450px;
  }
}

@media (max-width: 767px) {
  .services-panels {
    padding-bottom: 0;
    font-size: 1em;
  }
  .services-panels .pull-right {
    float: none !important;
  }
  .services-panels .row > [class^=col] {
    height: auto;
  }
  .services-panels .img-wrap {
    position: static;
    width: auto;
    height: 100%;
    margin-left: 0;
  }
  .services-panels .img-wrap img {
    position: static;
    transform: translateY(0);
    display: block;
    max-width: 100%;
    height: auto;
  }
}

.sv-info-panel {
  position: relative;
  padding: 4.44444em 15px 0 50px;
  background-color: #fafafa;
}

.sv-info-panel__heading {
  line-height: 1;
  margin-bottom: 1.66667em;
}

.sv-info-panel__heading:before, .sv-info-panel__heading:after {
  content: " ";
  display: table;
}

.sv-info-panel__heading:after {
  clear: both;
}

.sv-info-panel__heading > * {
  float: left;
  line-height: 1;
  margin: 0;
}

.sv-info-panel .cost {
  float: right;
  line-height: 2.57143em;
}

.sv-info-panel:before, .sv-info-panel:after {
  content: '';
  display: block;
  position: absolute;
}

.sv-info-panel:after {
  top: 0;
  height: 100%;
  width: 3000px;
  left: 100%;
  background-color: #fafafa;
}

.sv-info-panel:before {
  left: -30px;
  top: 50%;
  margin-top: -30px;
  border-style: solid;
  height: 0;
  width: 0;
  border-color: transparent #fafafa transparent transparent;
  border-width: 3rem 3rem 3rem 0;
}

.sv-info-panel--left {
  background-color: #fff;
  padding: 4.44444em 50px 0 15px;
}

.sv-info-panel--left:before {
  left: auto;
  right: -30px;
  border-style: solid;
  height: 0;
  width: 0;
  border-color: transparent transparent transparent #fff;
  border-width: 3rem 0 3rem 3rem;
}

.sv-info-panel--left:after {
  left: -3000px;
  /* white */
  background-color: #fff;
}

@media (max-width: 991px) {
  .sv-info-panel__heading > * {
    float: none;
  }
  .sv-info-panel .cost {
    float: none;
  }
}

@media (max-width: 767px) {
  .sv-info-panel {
    padding: 4.44444em 15px 3.72222em;
  }
  .sv-info-panel:before {
    left: 50%;
    right: auto;
    top: -30px;
    margin-top: 0;
    margin-left: -3rem;
    border-style: solid;
    height: 0;
    width: 0;
    border-color: transparent transparent #fafafa;
    border-width: 0 3rem 3rem;
  }
  .sv-info-panel--left:before {
    border-style: solid;
    height: 0;
    width: 0;
    border-color: transparent transparent #fff;
    border-width: 0 3rem 3rem;
  }
}

/*************************************

    GALLERIES

    = grid-sizer
    = gallery
    = gallery-filter
      ¬ overlay - hover state

    = blueimp-gallery

*************************************/
/**         masonry settings column        **/
.grid-sizer {
  padding: 0;
  margin: 0;
  min-height: 0;
  float: none;
}

.gallery .row {
  margin: 0 -4px;
}

.gallery--room {
  margin-bottom: 4em;
}

.gallery__item {
  padding: 0 4px;
  margin-bottom: 8px;
}

.gallery__img-wrap {
  position: relative;
  display: block;
  z-index: 2;
}

.gallery__img-wrap img {
  width: 100%;
}

.gallery .btn-wrap {
  text-align: center;
}

.gallery-filter {
  background-color: #333333;
  text-align: center;
  padding: 0.72222em 0;
}

.gallery-filter:before, .gallery-filter:after {
  content: " ";
  display: table;
}

.gallery-filter:after {
  clear: both;
}

.gallery-filter__item {
  display: inline-block;
  padding: 0 1.38889em;
}

.gallery-filter__item > button {
  display: block;
  color: #fff;
}

/**        ¬ overlay         **/
.gallery__img-wrap,
.hm-gallery-2__img-wrap,
.post-gallery__img-wrap {
  position: relative;
  display: block;
}

.gallery__img-wrap .overlay,
.hm-gallery-2__img-wrap .overlay,
.post-gallery__img-wrap .overlay {
  opacity: 0;
  transition: .4s;
}

.gallery__img-wrap .overlay:before,
.hm-gallery-2__img-wrap .overlay:before,
.post-gallery__img-wrap .overlay:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #8c8a88;
  background: url("./images/cross.svg") no-repeat 50%/80px auto;
}

.gallery__img-wrap:hover .overlay,
.hm-gallery-2__img-wrap:hover .overlay,
.post-gallery__img-wrap:hover .overlay {
  opacity: 1;
}

/*************************************

    ROOMs

    = rooms-list
      ¬ room-banner
      ¬ room-hero

*************************************/
.rooms-list .grid-item {
  margin-bottom: 30px;
}

@media (max-width: 554px) {
  .rooms-list .grid-item {
    float: none;
    width: 100%;
  }
}

.room-banner {
  position: relative;
  height: 370px;
  padding-bottom: 84px;
  overflow: hidden;
}

.room-banner__img {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  height: 370px;
  width: 556px;
  margin-top: -185px;
  margin-left: -278px;
}

.room-banner__img img {
  width: 100%;
}

.room-banner__footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 25px 9.00901%;
  border-top: 400px solid transparent;
  transition: all 0.4s ease;
}

.room-banner__footer:before, .room-banner__footer:after {
  content: " ";
  display: table;
}

.room-banner__footer:after {
  clear: both;
}

.room-banner__footer .tr:last-child:after {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 0;
}

.room-banner__footer .tr span {
  position: relative;
  z-index: 2;
}

@media (max-width: 991px) {
  .room-banner__footer {
    font-size: 0.72222em;
  }
}

@media (max-width: 767px) {
  .room-banner__footer {
    font-size: 0.61111em;
  }
}

.room-banner__title {
  font-family: "Playfair Display", Georgia, "Times New Roman", Times, serif;
  color: #fff;
  font-size: 1.66667em;
  line-height: 1;
}

.room-banner__cost {
  margin-left: 10px;
  letter-spacing: normal;
}

.room-banner__more {
  position: relative;
  padding: 44px 9.00901% 0;
  color: #fff;
  z-index: 2;
  opacity: 0;
  transition: .4s;
}

.room-banner__more > * {
  position: relative;
}

.room-banner.active .room-banner__footer {
  border-color: rgba(0, 0, 0, 0.7);
}

.room-banner.active .room-banner__more {
  opacity: 1;
}

.room-hero {
  color: #fff;
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.room-hero__main {
  max-width: 555px;
  margin: 0 auto;
}

.room-hero h1 {
  color: inherit;
  margin-top: 0.16667em;
  margin-bottom: 0.625em;
}

.room-hero p {
  margin-bottom: 1.77778em;
}

.room-hero .btn {
  max-width: 245px;
}

/*************************************

    ROOM DETAILS
    = room-gallery
      ¬ room-gallery__track
      ¬ gallery-item-link

    = room-service
      ¬ list-type
      ¬ table-responsive
      ¬ table
      ¬ tooltip-inner

*************************************/
.room-gallery {
  margin-bottom: 50px;
}

.room-gallery .container {
  overflow: hidden;
}

.room-gallery__inner {
  position: relative;
  height: 630px;
  overflow: hidden;
}

.room-gallery__inner > [class^=col] {
  height: 100%;
}

.room-gallery__slides {
  height: 100%;
}

.room-gallery__slides .swiper-wrapper,
.room-gallery__slides .swiper-slide {
  height: 100%;
}

.room-gallery .room-gallery__track {
  height: 590px;
  overflow: hidden;
}

.room-gallery .room-gallery__track .gallery-item-link {
  position: relative;
  height: 118px;
  padding-bottom: 8px;
  overflow: hidden;
}

.room-gallery .room-gallery__track .gallery-item-link:after {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  background-color: rgba(255, 255, 255, 0.7);
  opacity: 0;
  transition: opacity .4s ease;
}

.room-gallery .room-gallery__track .gallery-item-link img {
  width: 100%;
}

.room-gallery .room-gallery__track .gallery-item-link:hover:after,
.room-gallery .room-gallery__track .swiper-slide-active:after {
  opacity: 1;
}

@media (max-width: 1199px) {
  .room-gallery__inner {
    height: 465px;
  }
  .room-gallery__inner > .room-gallery__track {
    height: 100%;
  }
  .room-gallery__inner > .room-gallery__track .gallery-item-link {
    height: 21.5%;
    padding: 0;
    margin-bottom: 8px;
  }
}

@media (max-width: 991px) {
  .room-gallery__inner {
    height: 355px;
  }
}

@media (max-width: 767px) {
  .room-gallery .container {
    padding: 0;
  }
  .room-gallery__heading {
    text-align: center;
  }
  .room-gallery__inner {
    height: auto;
  }
  .room-gallery__inner > .room-gallery__track .swiper-wrapper {
    flex-direction: row;
    width: 9999px;
  }
  .room-gallery__inner > .room-gallery__track .gallery-item-link {
    width: 20vh;
    margin-bottom: 0;
  }
}

.room-service {
  padding-bottom: 4.44444em;
}

.room-service__title {
  margin-top: -9px;
}

.room-service .list-type ul {
  margin-bottom: 0;
}

.room-service .btn-wrap {
  margin-bottom: 0;
}

.room-service .btn-wrap > .btn {
  width: 100%;
}

.room-service .table-responsive {
  border: 1px solid #c6aa28;
  border-radius: 2px;
}

.room-service .table {
  background: transparent;
  margin-bottom: 0;
  font-size: 1em;
}

.room-service .table .fa-user {
  display: inline-block;
  padding: 0 4px;
  color: #333333;
}

.room-service .table td.price {
  letter-spacing: normal;
}

.room-service .table td.meal a {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 1em;
  line-height: .87em;
}

.room-service .table td.meal a sup {
  top: auto;
  display: inline-block;
  font-size: 55%;
  color: #c6aa28;
  font-weight: 700;
  width: 0;
  line-height: 1;
  vertical-align: top;
  transition: all 0.4s ease;
}

.room-service .table td.meal a:hover sup {
  color: #ccc;
}

.room-service .table td.meal a:focus sup {
  color: #000;
}

.room-service .table > thead > tr > th,
.room-service .table > tbody > tr > td {
  width: 33%;
  border-width: 0;
  text-align: center;
  vertical-align: top;
}

.room-service .table > thead {
  background-color: #c6aa28;
  color: #fff;
}

.room-service .table > tbody > tr {
  background-color: #fafafa;
}

.room-service .table > tbody > tr:nth-child(2n) {
  background-color: #dedede;
}

@media (max-width: 767px) {
  .room-service {
    text-align: center;
  }
  .room-service .table-responsive {
    margin-top: 2.11111em;
  }
}

.room-service .table .tooltip-inner {
  text-align: left;
}

/*************************************

    ABOUT PAGE

    = about-story
      ¬ slide
      ¬ img
      ¬ name
      ¬ stat
    ¬ panel-board
    ¬ triangle

    =about-team

*************************************/
.about-story .triangle {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -30px;
  border-style: solid;
  height: 0;
  width: 0;
  border-color: transparent #333333 transparent transparent;
  border-width: 3rem 3rem 3rem 0;
}

.about-story__slide {
  height: 100%;
  background-size: cover;
  background-position: 50%;
}

.about-story__slide:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.about-story__img {
  position: absolute;
  right: 130px;
  bottom: 0;
}

.about-story__name {
  position: absolute;
  top: 50%;
  right: 50px;
  transform: translateY(-50%);
  color: #fff;
}

.about-story__name small {
  display: block;
  line-height: 1;
}

@media (max-width: 1199px) {
  .about-story__img {
    right: 50px;
    z-index: 1;
    left: 0;
  }
  .about-story__name {
    z-index: 2;
  }
}

@media (max-width: 767px) {
  .about-story .panel-wd {
    position: absolute;
  }
  .about-story .triangle {
    position: absolute;
    top: 30px;
    border-style: solid;
    height: 0;
    width: 0;
    border-color: transparent #333333 transparent transparent;
    border-width: 0 8rem 8rem 0;
  }
  .about-story__name {
    position: static;
  }
  .about-story__img {
    right: auto;
    left: 5%;
  }
}

.about-story .panel-board {
  color: #fff;
}

.about-story .panel-board h1, .about-story .panel-board .h1 {
  color: #fff;
}

.about-story .panel-board__bottom {
  font-size: 1.67rem;
}

.about-story .panel-board__bottom [class^=col] {
  margin-bottom: 1.76471em;
}

@media (max-width: 1199px) {
  .about-story .panel-board {
    font-size: 1.63rem;
  }
  .about-story .panel-board__bottom {
    font-size: 1.17rem;
  }
}

@media (max-width: 991px) {
  .about-story .panel-board__bottom {
    font-size: 1.1rem;
  }
}

@media (max-width: 767px) {
  .about-story .panel-board {
    padding: 0;
    margin: 4.44444em auto 3.61111em;
    max-width: 480px;
  }
}

.about-team {
  font-size: 1.8rem;
  padding-bottom: 2.77778em;
}

.about-team .col-rs {
  margin-bottom: 1.66667em;
}

.about-team__img {
  max-width: 260px;
  margin: 0 auto 1.66667em;
}

.about-team__img img {
  border: 2px solid #c6aa28;
  max-width: 260px;
  width: 100%;
}

.about-team h3 {
  line-height: 1;
}

/*************************************

    PAGE FOOTER

    =page-footer
      ¬ title
      ¬ list
      ¬ link
      ¬ copyright

    = instagram-top-list
    = twitter-posts
      ¬ blog-posts
      ¬ date-twit

*************************************/
.page-footer {
  position: relative;
  background-color: #333333;
  color: #fff;
  font-size: 14px;
  padding-top: 50px;
  z-index: 1;
}

.page-footer p {
  margin-bottom: 0;
  line-height: 1.42857em;
}

.page-footer a {
  color: #fff;
}

.page-footer a:focus {
  color: #ccc;
}

.page-footer .link {
  color: #c6aa28;
}

.page-footer .link:focus {
  box-shadow: 0 1px #fff;
}

.page-footer [class*=col-] {
  margin-bottom: 50px;
}

.page-footer__title {
  color: #888;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 1.66667em;
}

.page-footer__list {
  margin-top: -5px;
}

.page-footer__list > li {
  padding: 11px 0;
  border-bottom: 1px solid #616161;
}

.page-footer__list > li:first-child {
  padding-top: 0;
}

.page-footer__list > li:last-child {
  border-bottom-width: 0;
}

.page-footer__copyright {
  padding: 25px 0;
  text-align: center;
  background-color: #000;
}

.instagram-top-list {
  margin-left: -14px;
}

.instagram-top-list a {
  position: relative;
  float: left;
  display: block;
  height: 78px;
  width: 78px;
  margin-left: 14px;
  margin-bottom: 15px;
  overflow: hidden;
}

.blog-posts a {
  box-shadow: none;
}

.twitter-posts {
  padding-left: 20px;
}

.twitter-posts > li {
  padding-bottom: 15px;
}

.twitter-posts .fa {
  float: left;
  margin-left: -20px;
  margin-top: 3px;
  color: #1da1f2;
}

.twitter-posts .date-twit {
  color: #888;
}

.twitter-posts .date-twit:hover, .twitter-posts .date-twit:focus {
  box-shadow: 0 1px #ccc;
}

/*************************************

    3.Components & modifiers:

                                   */
/*************************************

    Breadcrumbs for inner pages

*************************************/
.breadcrumbs-wrapper {
  border-bottom: 1px solid #dedede;
  background-color: #fafafa;
  margin-bottom: 4.44444em;
}

.breadcrumb {
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 0;
  font-size: 0.77778em;
  line-height: 1.42857em;
}

.breadcrumb:before, .breadcrumb:after {
  content: " ";
  display: table;
}

.breadcrumb:after {
  clear: both;
}

.breadcrumb > li {
  display: inline-block;
  vertical-align: middle;
}

.breadcrumb > li a, .breadcrumb > li.active {
  font-size: 1em;
  letter-spacing: 0.2em;
}

.breadcrumb > li + li:before {
  font-family: FontAwesome;
  content: "\f105";
  font-size: 1.28571em;
  font-weight: normal;
  padding: 0 15px;
}

.breadcrumb > li a {
  color: #c6aa28;
}

.breadcrumb > li a:hover {
  color: #ccc;
}

.breadcrumb > li a:focus {
  color: #000;
}

/*************************************

    Custom buttons

      ¬ --darker
      ¬ --warn

*************************************/
.button--reservation {
  color: #c6aa28;
}

.btn-wrap {
  margin: 2.33333em 0 1.66667em;
}

.btn {
  display: inline-block;
  padding: 15px 64px;
  font-family: Lato, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  border-width: 2px;
}

.btn--darker {
  color: #c6aa28;
  background-color: #333;
  border-color: #333;
}

.btn--darker:hover, .btn--darker:focus, .btn--darker:active:focus {
  color: #000;
  background-color: #fff;
  border-color: #000;
  box-shadow: 0 0 0 transparent;
  outline: none;
}

.btn--warn, .popover .clockpicker-button {
  color: #c6aa28;
  border-color: #c6aa28;
}

.btn--warn:hover, .popover .clockpicker-button:hover, .btn--warn:focus, .popover .clockpicker-button:focus {
  color: #fff;
  background-color: #ccc;
  border-color: #ccc;
  box-shadow: 0 0 0 transparent;
  outline: none;
}

.btn:active:focus {
  outline: none;
  box-shadow: none;
}

@media (max-width: 479px) {
  .btn {
    float: none !important;
    display: block;
    width: 100%;
    padding: 15px 3%;
    margin: 0 auto;
    font-size: 12px;
    letter-spacing: 0.08em;
  }
}

/*************************************

    Transitions, animations, states

    = logo
    = link
    = link-more

*************************************/
a,
h1, h2, h3, h4, h5, h6,
.btn,
.btn-play,
.control-panel__nav,
.logo,
.navbar__header,
.navbar__brand,
.form-control,
.label-control,
.flag,
.flag::before,
.icon-back {
  transition: all 0.4s ease;
}

a:hover,
a:hover .icon-back {
  color: #ccc;
}

a:focus {
  color: #000;
  outline: none;
}

a:active .icon-back {
  color: #000000;
}

a:hover, a:focus {
  text-decoration: none;
}

.hightlight {
  color: #ccc;
}

.hightgray {
  color: #000;
}

.link,
.link-more {
  color: #c6aa28;
  box-shadow: 0 1px #c6aa28;
}

.link:hover,
.link-more:hover {
  color: #ccc;
  box-shadow: 0 1px #ccc;
  border-color: #ccc;
}

.link:focus,
.link-more:focus {
  color: #000;
  box-shadow: 0 1px #000;
  border-color: #000;
}

.link--warn-bg,
.link-more--warn-bg {
  color: #fff;
  background-color: #c6aa28;
}

.link--warn-bg:hover, .link--warn-bg:focus,
.link-more--warn-bg:hover,
.link-more--warn-bg:focus {
  color: #fff;
  background-color: #ccc;
  box-shadow: 0 0 #ccc;
}

.navbar-brand .logo:hover, .navbar-brand .logo:focus, .navbar-brand .logo:active {
  fill: #ccc;
  color: #ccc;
  opacity: .4;
}

.instagram-top-list a:after,
.sidebar-thumb:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ccc;
  opacity: 0;
  transition: all 0.4s ease;
}

.sidebar-preview__content:hover .sidebar-thumb:after,
.sidebar-preview__content:focus .sidebar-thumb:after {
  opacity: .7;
}

.instagram-top-list a:hover:after, .instagram-top-list a:focus:after {
  opacity: .7;
}

.gallery-filter__item > button, .control-panel__tabs > * {
  position: relative;
  line-height: 2.66667em;
  transition: all 0.4s ease;
}

.gallery-filter__item > button:after, .control-panel__tabs > :after {
  position: absolute;
  content: ' ';
  left: 0;
  bottom: 5px;
  height: 0;
  width: 96.6%;
  opacity: 0;
  transform: translateY(0);
  background-color: #c6aa28;
  transition: all 0.4s ease;
}

.gallery-filter__item > button.active, .control-panel__tabs > .active, .gallery-filter__item > button:focus, .control-panel__tabs > :focus {
  color: #c6aa28;
}

.gallery-filter__item > button.active:after, .control-panel__tabs > .active:after, .gallery-filter__item > button:focus:after, .control-panel__tabs > :focus:after {
  height: 2px;
  opacity: 1;
  transform: translateY(5px);
}

/*************************************

    Decorative elements

      = bullet
        ¬ bullet-rotate
        ¬ bullet-inline

      = stat
      = flag
      = tags

*************************************/
.intro-slider .intro-hero .intro-title--decor:after, .hm-features-2 span.h2:after, .blog-sidebar__title:before, .stat-num:after {
  content: ' ';
  font-size: 9px;
  line-height: 1;
  display: block;
  height: 1em;
  width: 1em;
  background-color: #c6aa28;
  transform: rotate(-45deg);
}

.blog-sidebar__title:before {
  display: inline-block;
  margin-right: 10px;
  margin-top: -0.5em;
  vertical-align: middle;
}

.brand-decor {
  max-width: 420px;
  width: 100%;
}

.stat-num span {
  display: inline-block;
  width: 100%;
  font-family: Lato, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.66667em;
  line-height: 1;
  color: #c6aa28;
}

.stat-num:after {
  margin: 1.66667em auto 0.55556em;
}

.stat-text {
  font-family: "Playfair Display", Georgia, "Times New Roman", Times, serif;
  font-size: 1.66667em;
  line-height: 1.4;
  color: #fff;
}

.flag {
  display: inline-block;
  position: relative;
  height: 30px;
  font-family: Lato, sans-serif;
  letter-spacing: 0.2em;
  font-weight: 700;
  line-height: 30px;
  width: 145px;
  margin-left: 15px;
  font-size: 14px;
  text-align: center;
  color: #fff;
  background-color: #c6aa28;
  border-radius: 2px;
  border-bottom-right-radius: 0;
  text-transform: uppercase;
  opacity: 1;
  z-index: 1;
}

.flag::before {
  content: "";
  left: -14px;
  position: absolute;
  border-style: solid;
  height: 0;
  width: 0;
  border-color: transparent #c6aa28 transparent transparent;
  border-width: 1.5rem 1.5rem 1.5rem 0;
  border-radius: 2px;
}

.flag--top-right {
  position: absolute;
  top: 0;
  right: 0;
}

.rounder-right {
  border-top-right-radius: 8px;
  overflow: hidden;
}

.rounder {
  border-radius: 2px;
}

.blog-tags {
  margin-left: -15px;
  margin-bottom: -15px;
  font-size: 13px;
}

.blog-tags:before, .blog-tags:after {
  content: " ";
  display: table;
}

.blog-tags:after {
  clear: both;
}

.blog-tags > li {
  float: left;
  margin-left: 15px;
  margin-bottom: 15px;
}

.blog-tags > li a {
  padding: 7px 15px;
  line-height: 1em;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  letter-spacing: 0.15em;
}

.blog-tags--sidebar a {
  border-color: #c6aa28;
}

.blog-tags--footer a {
  color: #fff;
  border-color: #fff;
}

.blog-tags a:hover, .blog-tags a:focus {
  color: #fff;
  border-color: #ccc;
  background-color: #ccc;
}

.tooltip-inner {
  display: inline-block;
  max-width: 260px;
  font-size: 1.63rem;
  font-family: Georgia, "Times New Roman", Times, serif;
  padding: 15px;
  color: #fff;
  text-align: center;
  background-color: #333;
  border-radius: 2px;
}

@media (max-width: 359px) {
  .tooltip-inner {
    max-width: 165px;
  }
}

/*!
 * Datepicker for Bootstrap
 *
 * Copyright 2012 Stefan Petre
 * Improvements by Ashley Novak
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 */
.datepicker table tr td, .datepicker table tr th {
  text-align: center;
  width: 30px;
  height: 30px;
  border-radius: 2px;
  border: none;
}

.table-striped .datepicker table tr td, .table-striped .datepicker table tr th {
  background-color: transparent;
}

.datepicker table tr td.day:hover, .datepicker table tr td.day.focused {
  background: #dedede;
  cursor: pointer;
}

.datepicker table tr td.old, .datepicker table tr td.new {
  color: #888;
}

.datepicker table tr td.disabled, .datepicker table tr td.disabled:hover {
  background: none;
  color: #888;
  cursor: default;
}

.datepicker table tr td.today, .datepicker table tr td.today:hover, .datepicker table tr td.today.disabled, .datepicker table tr td.today.disabled:hover {
  color: #333333;
  background-color: rgba(198, 170, 40, 0.75);
  border-color: rgba(113, 97, 23, 0.75);
}

.datepicker table tr td.today:focus, .datepicker table tr td.today:active, .datepicker table tr td.today.active, .datepicker table tr td.today:hover:focus, .datepicker table tr td.today:hover:active, .datepicker table tr td.today:hover.active, .datepicker table tr td.today.disabled:focus, .datepicker table tr td.today.disabled:active, .datepicker table tr td.today.disabled.active, .datepicker table tr td.today.disabled:hover:focus, .datepicker table tr td.today.disabled:hover:active, .datepicker table tr td.today.disabled:hover.active {
  color: #333333;
  background-color: rgba(198, 170, 40, 0.75);
  border-color: rgba(62, 53, 13, 0.75);
}

.datepicker table tr td.today:hover, .datepicker table tr td.today:hover:hover, .datepicker table tr td.today.disabled:hover, .datepicker table tr td.today.disabled:hover:hover {
  color: #333333;
  background-color: rgba(164, 141, 33, 0.75);
  border-color: rgba(62, 53, 13, 0.75);
}

.datepicker table tr td.today:hover:hover {
  color: #000;
}

.datepicker table tr td.today.active:hover {
  color: #fff;
}

.datepicker table tr td.range, .datepicker table tr td.range:hover, .datepicker table tr td.range.disabled, .datepicker table tr td.range.disabled:hover {
  background: #dedede;
  border-radius: 0;
}

.datepicker table tr td.range.today, .datepicker table tr td.range.today:hover, .datepicker table tr td.range.today.disabled, .datepicker table tr td.range.today.disabled:hover {
  color: #000;
  background-color: rgba(198, 170, 40, 0.75);
  border-color: rgba(113, 97, 23, 0.75);
  border-radius: 0;
}

.datepicker table tr td.range.today:focus, .datepicker table tr td.range.today:active, .datepicker table tr td.range.today.active, .datepicker table tr td.range.today:hover:focus, .datepicker table tr td.range.today:hover:active, .datepicker table tr td.range.today:hover.active, .datepicker table tr td.range.today.disabled:focus, .datepicker table tr td.range.today.disabled:active, .datepicker table tr td.range.today.disabled.active, .datepicker table tr td.range.today.disabled:hover:focus, .datepicker table tr td.range.today.disabled:hover:active, .datepicker table tr td.range.today.disabled:hover.active {
  color: #000;
  background-color: rgba(198, 170, 40, 0.75);
  border-color: rgba(62, 53, 13, 0.75);
}

.datepicker table tr td.range.today:hover, .datepicker table tr td.range.today:hover:hover, .datepicker table tr td.range.today.disabled:hover, .datepicker table tr td.range.today.disabled:hover:hover {
  color: #000;
  background-color: rgba(164, 141, 33, 0.75);
  border-color: rgba(62, 53, 13, 0.75);
}

.datepicker table tr td.selected, .datepicker table tr td.selected:hover, .datepicker table tr td.selected.disabled, .datepicker table tr td.selected.disabled:hover {
  color: #fff;
  background-color: #888;
  border-color: #555555;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.datepicker table tr td.selected:focus, .datepicker table tr td.selected:active, .datepicker table tr td.selected.active, .datepicker table tr td.selected:hover:focus, .datepicker table tr td.selected:hover:active, .datepicker table tr td.selected:hover.active, .datepicker table tr td.selected.disabled:focus, .datepicker table tr td.selected.disabled:active, .datepicker table tr td.selected.disabled.active, .datepicker table tr td.selected.disabled:hover:focus, .datepicker table tr td.selected.disabled:hover:active, .datepicker table tr td.selected.disabled:hover.active {
  color: #fff;
  background-color: #888;
  border-color: #373737;
}

.datepicker table tr td.selected:hover, .datepicker table tr td.selected:hover:hover, .datepicker table tr td.selected.disabled:hover, .datepicker table tr td.selected.disabled:hover:hover {
  color: #fff;
  background-color: #747474;
  border-color: #373737;
}

.datepicker table tr td.active, .datepicker table tr td.active:hover, .datepicker table tr td.active.disabled, .datepicker table tr td.active.disabled:hover {
  color: #fff;
  background-color: rgba(198, 170, 40, 0.75);
  border-color: rgba(198, 170, 40, 0.75);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.datepicker table tr td.active:focus, .datepicker table tr td.active:active, .datepicker table tr td.active.active, .datepicker table tr td.active:hover:focus, .datepicker table tr td.active:hover:active, .datepicker table tr td.active:hover.active, .datepicker table tr td.active.disabled:focus, .datepicker table tr td.active.disabled:active, .datepicker table tr td.active.disabled.active, .datepicker table tr td.active.disabled:hover:focus, .datepicker table tr td.active.disabled:hover:active, .datepicker table tr td.active.disabled:hover.active {
  color: #fff;
  background-color: rgba(198, 170, 40, 0.75);
  border-color: rgba(147, 126, 30, 0.75);
}

.datepicker table tr td.active:hover, .datepicker table tr td.active:hover:hover, .datepicker table tr td.active.disabled:hover, .datepicker table tr td.active.disabled:hover:hover {
  color: #fff;
  background-color: rgba(164, 141, 33, 0.75);
  border-color: rgba(147, 126, 30, 0.75);
}

.datepicker table tr td span {
  display: block;
  width: 23%;
  height: 54px;
  line-height: 54px;
  float: left;
  margin: 1%;
  cursor: pointer;
  border-radius: 2px;
}

.datepicker table tr td span:hover {
  background: #dedede;
}

.datepicker table tr td span.disabled, .datepicker table tr td span.disabled:hover {
  background: none;
  color: #888;
  cursor: default;
}

.datepicker table tr td span.active, .datepicker table tr td span.active:hover, .datepicker table tr td span.active:hover.active:hover, .datepicker table tr td span.active.disabled, .datepicker table tr td span.active.disabled:hover {
  color: #fff;
  background-color: rgba(198, 170, 40, 0.75);
  border-color: rgba(198, 170, 40, 0.75);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.datepicker table tr td span.active:focus, .datepicker table tr td span.active:active, .datepicker table tr td span.active.active, .datepicker table tr td span.active:hover:focus, .datepicker table tr td span.active:hover:active, .datepicker table tr td span.active:hover.active, .datepicker table tr td span.active:hover.active:hover:focus, .datepicker table tr td span.active:hover.active:hover:active, .datepicker table tr td span.active:hover.active:hover.active, .datepicker table tr td span.active.disabled:focus, .datepicker table tr td span.active.disabled:active, .datepicker table tr td span.active.disabled.active, .datepicker table tr td span.active.disabled:hover:focus, .datepicker table tr td span.active.disabled:hover:active, .datepicker table tr td span.active.disabled:hover.active {
  color: #fff;
  background-color: rgba(198, 170, 40, 0.75);
  border-color: rgba(147, 126, 30, 0.75);
}

.datepicker table tr td span.active:hover, .datepicker table tr td span.active:hover:hover, .datepicker table tr td span.active:hover.active:hover:hover, .datepicker table tr td span.active.disabled:hover, .datepicker table tr td span.active.disabled:hover:hover {
  color: #fff;
  background-color: rgba(164, 141, 33, 0.75);
  border-color: rgba(147, 126, 30, 0.75);
}

.datepicker thead tr:first-child th:hover,
.datepicker tfoot tr th:hover {
  background: #dedede;
}

/*************************************

    CUSTOM FORM

    = form-wrap
    = form-comments
    = mp-menu

    = datepicker
    = clockpicker

    = modal form reservation

*************************************/
textarea {
  resize: none;
  min-height: 200px;
}

input[type='checkbox'],
input[type='radio'] {
  cursor: pointer;
}

.noCursor {
  cursor: default;
}

.fzReset {
  font-size: 0;
}

.form-wrap .input-wrap {
  margin-bottom: 20px;
}

.form-wrap .label-control {
  display: inline-block;
  font-size: 0.77778em;
  cursor: pointer;
}

.form-wrap .label-warn {
  color: #c6aa28;
}

@media (min-width: 768px) {
  .form-wrap .input-row {
    margin: 0 -10px;
  }
  .form-wrap .input-wrap {
    padding: 0 10px;
  }
}

.form-wrap [type=submit] {
  padding-left: 65px;
  padding-right: 65px;
}

.form-wrap .datepicker,
.form-wrap .clockpicker .form-control {
  font-family: Lato, "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.2em;
  border-radius: 2px;
}

/**        =form-reservation         **/
.form-res .btn {
  width: 100%;
}

.form-res .btn-wrap {
  margin-top: 0;
}

.form-res .number-of-reversed .br-widget a {
  display: inline-block;
  width: 13px;
  height: 37px;
  margin-right: 8px;
  user-select: none;
  cursor: pointer;
  transition: none;
  background: url("./images/man.svg") no-repeat;
}

.form-res .number-of-reversed .br-widget .br-active,
.form-res .number-of-reversed .br-widget .br-selected,
.form-res .number-of-reversed .br-widget .br-current {
  background-position: 50% -40px;
}

.form-res .number-of-reversed .br-wrapper {
  position: relative;
}

.form-res .number-of-reversed .br-current-rating {
  float: right;
  margin-top: 3px;
  font-size: 3rem;
  letter-spacing: 0;
}

.form-res .number-of-reversed input[type='checkbox'] {
  display: none;
  margin: 0;
}

.form-res .number-of-reversed input[type='checkbox'] + label {
  margin: 0;
  font-size: 14px;
  white-space: nowrap;
  user-select: none;
}

.form-res .number-of-reversed input[type='checkbox'] + label > * {
  display: inline-block;
  vertical-align: middle;
}

.form-res .number-of-reversed input[type='checkbox'] + label .fa {
  width: 14px;
  height: 14px;
  margin-right: 8px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  color: #c6aa28;
  border-radius: 3px;
  cursor: pointer;
}

.form-res .number-of-reversed input[type='checkbox'] + label .fa:before {
  opacity: 0;
}

.form-res .number-of-reversed input[type='checkbox'] + label .span {
  max-width: 130px;
  line-height: 20px;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 14px;
  color: #000;
  font-weight: 400;
  text-transform: none;
  letter-spacing: normal;
  white-space: normal;
}

.form-res .number-of-reversed input[type='checkbox'] + label:hover .span,
.form-res .number-of-reversed input[type='checkbox'] + label:focus .span {
  color: #000;
}

.form-res .number-of-reversed input[type='checkbox']:checked + label .fa:before {
  opacity: 1;
}

.form-res .number-of-reversed .disable:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
}

.form-res .number-of-reversed .disable a {
  opacity: .4;
}

@media (max-width: 767px) {
  .form-res .br-wrapper {
    text-align: right;
  }
}

@media (max-width: 479px) {
  .form-res .number-of-reversed .br-current-rating {
    display: block;
    float: none;
    margin-top: 10px;
    margin-bottom: -10px;
    font-size: 37px;
  }
  .form-res .br-wrapper,
  .form-res .number-of-reversed .br-current-rating {
    text-align: center;
  }
  .form-res .number-of-reversed input[type='checkbox'] + label {
    width: 100%;
    max-width: 152px;
    margin: 0 auto;
  }
}

.mp-menu .form-res {
  padding: 15px;
}

.mp-menu .form-res .number-of-reversed {
  width: 100%;
  max-width: none;
  margin: 0 auto;
  white-space: nowrap;
}

.mp-menu .form-res .number-of-reversed .input-wrap {
  display: inline-block;
  width: 48.5%;
  margin-bottom: 0;
}

.mp-menu .form-res .number-of-reversed .br-widget a {
  width: 12px;
  margin-right: 5px;
}

.mp-menu .form-res .number-of-reversed .br-current-rating {
  float: none;
  display: inline-block;
  vertical-align: top;
  margin-top: auto;
  margin-bottom: auto;
}

.mp-menu .form-res .br-wrapper,
.mp-menu .form-res .number-of-reversed .br-current-rating {
  text-align: left;
}

.mp-menu .form-res .number-of-reversed input[type='checkbox'] + label {
  width: 100%;
  max-width: none;
  white-space: nowrap;
}

.mp-menu .form-res .number-of-reversed input[type='checkbox'] + label .fa {
  margin-right: 4px;
  width: 12px;
  height: 12px;
}

.mp-menu .form-res .number-of-reversed input[type='checkbox'] + label span {
  width: 100%;
  max-width: 110px;
  font-size: 13px;
  white-space: normal;
}

.mp-menu .form-res .btn {
  padding: 15px 0;
}

.datepicker table tr td,
.popover {
  font-family: Lato, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

/**        = datepicker         **/
.datepicker {
  font-family: Lato, "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
}

.datepicker table > thead {
  font-weight: 700;
  border-bottom: 1px solid #e0e0e0;
}

.datepicker table > thead > tr + tr > th {
  background-color: #fff;
}

.datepicker table > thead > tr + tr > th:hover {
  background-color: #fff;
  color: #000;
}

.datepicker .prev, .datepicker .next {
  position: relative;
  font-family: FontAwesome;
  text-indent: -9999px;
  color: #c6aa28;
}

.datepicker .prev:after, .datepicker .next:after {
  text-indent: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.datepicker .prev:hover:after, .datepicker .prev:focus:after, .datepicker .next:hover:after, .datepicker .next:focus:after {
  color: #333333;
}

.datepicker .prev:active:after, .datepicker .next:active:after {
  color: #888;
}

.datepicker .prev:after {
  content: "\f104";
}

.datepicker .next:after {
  content: "\f105";
}

/**        =clockpicker         **/
.popover {
  padding: 0;
  border-radius: 2px;
  border-color: transparent;
}

.popover-content {
  background-color: #fafafa;
}

.popover .clockpicker-button {
  border-width: 0;
  border-radius: 0;
}

.popover .clockpicker-plate {
  border-color: #c6aa28;
}

.popover .popover-title {
  color: #c6aa28;
}

.popover .popover-title span {
  color: #c6aa28;
}

.clockpicker-tick.active,
.clockpicker-tick:hover {
  background-color: rgba(198, 170, 40, 0.25);
}

.clockpicker-canvas-bg-trans,
.clockpicker-canvas-bg {
  fill: rgba(198, 170, 40, 0.25);
}

.clockpicker-canvas-bearing, .clockpicker-canvas-fg {
  fill: #c6aa28;
}

.clockpicker-canvas line,
.clockpicker-canvas-bearing, .clockpicker-canvas-fg {
  stroke: #c6aa28;
}

/**        = modal form reservation         **/
.modal .modal-dialog {
  width: 100%;
  max-width: 570px;
  padding: 0 0 2.77778em;
  background-color: #fff;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.3), 0px 2px 2px 0px rgba(0, 0, 0, 0.21), 0px 1px 5px 0px rgba(0, 0, 0, 0.18);
}

.modal .modal-dialog .close {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 7.01754%;
  margin-right: 7.01754%;
  font-size: 24px;
  line-height: 1;
  z-index: 1;
  transition: all 0.4s ease;
}

.modal .modal-dialog .modal-content {
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
  box-shadow: none;
  border-width: 0;
  box-shadow: none;
}

.modal .modal-dialog .modal-body {
  padding: 0;
}

.modal .modal-dialog .hr-heading {
  margin-bottom: 1.66667em;
}

.modal .modal-dialog .modal-header {
  padding: 0;
  border-bottom-width: 0;
}

.modal .modal-dialog .modal-header span {
  display: inline-block;
  line-height: 1;
}

.modal .form-res .btn-wrap {
  margin-bottom: 0;
}

.modal .btn {
  display: block;
  width: 100%;
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
  padding: 15px 0;
}

@media (max-width: 767px) {
  .modal .modal-dialog {
    margin: 10px auto;
  }
}

@media (max-width: 479px) {
  .modal {
    margin: 0 -17px 0 0;
  }
  .modal .modal-dialog {
    margin: 0 auto;
  }
  .modal .modal-content {
    padding: 0 25px;
  }
}

/*************************************

    Contact elements

    = contacts-list
    = social-list
    = contacts-share
    = map

*************************************/
.social-list {
  font-size: 0;
}

.social-list > li {
  display: inline-block;
  margin-right: 6px;
  margin-left: -2px;
  letter-spacing: 0;
}

.social-list > li:last-child {
  margin-right: 0;
}

.social-list > li > a {
  font-size: 1.8rem;
}

.contacts-list__top {
  text-align: left;
}

.contacts-list .h3 {
  display: block;
}

.contacts-list__item {
  line-height: 1;
  margin-bottom: 20px;
}

.contacts-list [href^=tel],
.contacts-list [href^=mailto],
.contacts-list .address {
  display: inline-block;
  line-height: 1.42857em;
}

@media (max-width: 767px) {
  .contacts-list {
    text-align: center;
  }
  .contacts-list__item {
    margin-bottom: 25px;
  }
}

#map {
  width: 100%;
}

.page-home #map {
  height: 360px;
}

.main-contacts {
  padding-bottom: 4.44444em;
}

.main-contacts #map {
  height: 551px;
  margin-top: 0.33333em;
}

@media (max-width: 1199px) {
  .main-contacts #map {
    height: 486px;
  }
}

@media (max-width: 991px) {
  .main-contacts #map {
    height: 407px;
  }
}

@media (max-width: 767px) {
  .main-contacts {
    padding-bottom: 0;
  }
  .main-contacts #map {
    height: 360px;
    margin-top: 4.44444em;
  }
}

@media (max-width: 767px) {
  .contacts-share {
    text-align: center;
  }
}

/*************************************

    Control carousel, pagination, tabs, video, lighbox

    Carousel
    = control-panel
      ¬ nav
      ¬ prev
      ¬ next
      ¬ tabs
      ¬ dots
      ¬ filter
      ¬ mobile

    Video:
    = elemets for video
      ¬ btn-play
    = video-contain

    Lightbox:
    = blueimp-gallery-controls
      ¬ prev
      ¬ next
      ¬ close
      ¬ indicator

*************************************/
.owl-carousel .owl-item {
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
}

/**        = dots and tabs, navigation arrows         **/
.control-panel {
  position: relative;
  padding: 0 50px;
  z-index: 10;
  /**        ¬ arrows         **/
}

.control-panel__nav {
  position: absolute;
  top: 50%;
  width: 48px;
  height: 48px;
  background-color: #333333;
  border: 2px solid #333333;
  border-radius: 2px;
  color: #c6aa28;
  transform: translateY(-50%);
  z-index: 10;
}

.control-panel__nav .fa {
  font-size: 32px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1;
  vertical-align: middle;
}

.control-panel__nav:hover, .control-panel__nav:focus, .control-panel__nav:active {
  background-color: #fff;
  border-color: #000;
  color: #000;
}

.control-panel .prev {
  left: 0;
  background-image: url("");
}

.control-panel .next {
  right: 0;
  background-image: url("");
}

.control-panel__tabs {
  text-align: center;
  margin-left: -50px;
}

.control-panel__tabs > * {
  display: inline-block;
  margin-left: 50px;
  font-family: Lato, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1em;
  color: #888;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  cursor: pointer;
}

.control-panel__dots {
  line-height: 1;
  padding: 15px 0;
  text-align: center;
}

.control-panel__dots .owl-dot,
.control-panel__dots .swiper-pagination-bullet {
  display: inline-block;
  font-size: 18px;
  width: auto;
  height: auto;
  padding: 0 0.38889em;
  vertical-align: middle;
  cursor: pointer;
}

.control-panel__dots .swiper-pagination-bullet {
  background-color: transparent;
  border-radius: 0;
  opacity: 1;
}

.control-panel__dots .swiper-pagination-bullet:after {
  content: '';
}

.control-panel__dots .owl-dot > span,
.control-panel__dots .swiper-pagination-bullet:after {
  position: relative;
  display: block;
  width: 0.66667em;
  height: 0.66667em;
  top: 50%;
  margin: 0 auto;
  margin-top: -0.33333em;
  transform: rotate(45deg);
  background-color: #fff;
  transition: all 0.4s ease;
}

.control-panel__dots .owl-dot > span:hover,
.control-panel__dots .swiper-pagination-bullet:hover:after {
  background-color: #ccc;
}

.control-panel__dots .owl-dot.active > span,
.control-panel__dots .swiper-pagination-bullet-active:after {
  background-color: #c6aa28;
}

@media (max-width: 991px) {
  .control-panel--filter {
    min-height: 30px;
    padding: 0 30px;
  }
  .control-panel--filter .prev,
  .control-panel--filter .next {
    font-size: 30px;
  }
  .control-panel__tabs {
    margin-left: -30px;
  }
  .control-panel__tabs > * {
    line-height: 30px;
    margin-left: 30px;
  }
}

@media (max-width: 767px) {
  .control-panel--mobile {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
  }
  .control-panel__tabs {
    margin-left: -5%;
    font-size: 13px;
  }
  .control-panel__tabs > * {
    margin-left: 5%;
  }
}

@media (max-width: 767px) and (max-width: 379px) {
  .control-panel__tabs > * {
    letter-spacing: 0.08em;
  }
}

/**        = elemets for video         **/
.btn-play {
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #333333;
  cursor: pointer;
  z-index: 5;
}

.btn-play:after {
  content: ' ';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -22px 0 0 -10px;
  border-style: solid;
  height: 0;
  width: 0;
  border-color: transparent transparent transparent #c6aa28;
  border-width: 2.2rem 0 2.2rem 3rem;
  transition: all 0.4s ease;
}

.btn-play:hover, .btn-play:focus {
  background-color: #ccc;
}

.btn-play:hover:after, .btn-play:focus:after {
  border-style: solid;
  height: 0;
  width: 0;
  border-color: transparent transparent transparent #000;
  border-width: 2.2rem 0 2.2rem 3rem;
}

.btn-play:active {
  background-color: #fafafa;
}

.btn-play:active:after {
  border-style: solid;
  height: 0;
  width: 0;
  border-color: transparent transparent transparent #888;
  border-width: 2.2rem 0 2.2rem 3rem;
}

/**        = video         **/
.video-contain {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  border-top-right-radius: 3px;
  border: 1px solid transparent;
  /**        ¬ helper classess         **/
}

.video-contain__preview {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  opacity: 1;
  visibility: visible;
  z-index: 2;
}

.video-contain .video-controls {
  left: 0;
  top: 0;
  width: 100%;
  opacity: 1;
  visibility: visible;
  z-index: 2;
}

.video-contain__preview {
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 0.4s ease;
}

.video-contain .btn-play {
  /**  width 80px  **/
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;
  visibility: visible;
  opacity: 1;
}

.video-contain__wrapper, .video-contain__wrapper embed,
.video-contain__wrapper object,
.video-contain__wrapper iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.video-contain__preview.hover {
  border-color: #f8f8f8;
}

.video-contain__preview.hover .overlay {
  background-color: rgba(255, 255, 255, 0.85);
}

.video-contain__preview.hover .flag {
  opacity: .7;
}

.video-contain.actived .video-contain__preview,
.video-contain.actived .btn-play {
  visibility: hidden;
  opacity: 0;
}

/**        = Lightbox         **/
.blueimp-gallery-controls .prev,
.blueimp-gallery-controls .next {
  width: 40px;
  height: 40px;
  transform: rotate(45deg);
  transition: all .4s;
  border-width: 1px;
  border-color: transparent transparent #fff #fff;
  background-color: transparent;
  border-radius: 0;
}

.blueimp-gallery-controls .prev {
  left: 40px;
}

.blueimp-gallery-controls .next {
  right: 40px;
  border-color: #fff #fff transparent transparent;
}

.blueimp-gallery-controls .close {
  top: 40px;
  right: 40px;
  font-size: 34px;
  width: 1em;
  height: 1em;
  transform: rotate(45deg);
}

.blueimp-gallery-controls .close:before, .blueimp-gallery-controls .close:after {
  left: 0;
  top: 0;
  position: absolute;
  content: '';
  background-color: #fff;
}

.blueimp-gallery-controls .close:before {
  top: 50%;
  width: 1em;
  height: 2px;
  margin-top: -1px;
}

.blueimp-gallery-controls .close:after {
  left: 50%;
  width: 2px;
  height: 1em;
  margin-left: -1px;
}

.blueimp-gallery-controls > .indicator > li {
  position: relative;
  background-size: cover;
  transition: all .4s;
  z-index: 1;
  border: none;
}

.blueimp-gallery-controls > .indicator > li:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.7);
  transition: all .4s;
}

.blueimp-gallery-controls > .indicator > li.active:after {
  opacity: 1;
}

/*************************************

    SECTION PANELS

    = panels-wrapper
      ¬ slide
      ¬ --dark

    = panel-wd
      ¬ slide
      ¬ --right
      ¬ --dark

    = panel-board
      ¬ title
      ¬ main
      ¬ --right

    = hm-history
      ¬ carousel
      ¬ controls
      ¬ controls--mobile
      ¬ context

*************************************/
.panels-wrapper {
  position: relative;
  background-color: #fafafa;
  min-height: 640px;
  overflow: hidden;
}

.panels-wrapper--dark {
  background-color: #333333;
}

.panels-wrapper .container,
.panels-wrapper .row,
.panels-wrapper .row > *,
.panels-wrapper .panel-wd, .panels-wrapper__slide,
.panels-wrapper .panel-board {
  height: 100%;
}

.panel-wd {
  position: absolute;
  left: 0;
  padding: 0;
}

.panel-wd:after {
  content: '';
  position: absolute;
  top: 50%;
  right: -25px;
  margin-top: -25px;
  width: 50px;
  height: 50px;
  background-color: #fafafa;
  transform: rotate(45deg);
  z-index: 1;
}

.panel-wd__slide {
  position: relative;
  min-height: 640px;
  overflow: hidden;
}

.panel-wd__slide img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.panel-wd--right {
  right: 0;
  left: auto;
}

.panel-wd--right:after {
  left: -25px;
  right: auto;
}

.panel-wd--dark:after {
  background-color: #333333;
}

@media (max-width: 767px) {
  .panel-wd {
    min-height: auto;
    position: relative;
  }
  .panel-wd:after {
    display: none;
    top: auto;
    left: 50%;
    bottom: -25px;
    margin-left: -25px;
  }
  .panel-wd--right:after {
    top: 0;
    bottom: auto;
  }
}

.panel-wd__slide {
  min-height: auto;
}

.panel-wd__slide img {
  position: static;
  transform: translate(0, 0);
}

.panel-board {
  position: relative;
  z-index: 1;
  padding: 3.88889em 6.30631% 2.22222em 0;
  transition: .4s;
}

.panel-board__title, .hm-gallery-2__header h1, .hm-gallery-2__header .h1, .room-gallery__title {
  display: inline-block;
  margin-top: 0;
  padding-bottom: 13px;
  border-bottom: 1px solid #c6aa28;
}

.panel-board__main {
  margin-bottom: 2.77778em;
}

.panel-board--right {
  padding: 3.88889em 0 2.22222em 6.30631%;
}

@media (max-width: 1199px) {
  .panel-board {
    font-size: 1.63rem;
  }
}

@media (max-width: 767px) {
  .panel-board {
    width: 100%;
    text-align: center;
    padding: 4.44444em 0;
  }
}

/**        ¬ hm-history-2         **/
.hm-history-carousel .panel-wd__slide img {
  width: auto;
}

.hm-history__controls {
  position: absolute;
  left: 7.5%;
  right: 7.5%;
  bottom: 7.5%;
  max-width: 850px;
  margin: 0 auto;
}

.hm-history .panel-wd__slide {
  min-height: 640px;
}

@media (min-width: 768px) {
  .hm-history .panel-wd__slide img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (min-width: 1920px) {
  .hm-history .panel-wd__slide img {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .hm-history-carousel .panel-wd__slide img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
  .hm-history .panel-wd {
    position: relative;
  }
  .hm-history .panels-wrapper,
  .hm-history .panel-wd__slide {
    min-height: 0;
  }
}

/*************************************

    Mobile menu Slideout.js

************************************/
.menu-trigger {
  position: relative;
  float: right;
  width: 40px;
  height: 30px;
  cursor: pointer;
}

.menu-trigger:before {
  position: absolute;
  top: 13px;
  left: 0;
  width: 100%;
  height: 4px;
  background: #333333;
  box-shadow: 0 6px transparent, 0 -8px #333333, 0 18px transparent, 0 8px #333333;
  content: '';
  transition: all .31s ease;
}

.menu-trigger:hover:before {
  box-shadow: 0 6px transparent, 0 -12px #333333, 0 18px transparent, 0 12px #333333;
}

.slideout-menu {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  width: 256px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: none;
}

.slideout-panel {
  position: relative;
  z-index: 1;
}

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
  overflow: hidden;
}

.slideout-open .slideout-menu {
  display: block;
}

.navbar,
.page-footer {
  transition: all .3s ease;
}

.navbar.open,
.page-footer.open {
  transform: translate3d(256px, 0, 0);
}

.slideout-open .slideout-menu {
  display: block;
}

.over-page {
  visibility: hidden;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0;
  background: rgba(0, 0, 0, 0.6);
  transform: translate3d(0, 0, 0);
  transition: all .3s ease;
}

.over-page.open {
  opacity: .3;
  visibility: visible;
  background: #000;
  z-index: 1040;
  transform: translate3d(256px, 0, 0);
  transition: opacity .3s ease .3s;
}

.panel-menu {
  background-color: #fff;
}

.panel-menu .panel-menu-header {
  width: 256px;
  text-align: center;
  cursor: pointer;
}

.panel-menu .panel-menu-header h1 {
  padding: 21px 0;
  margin: 0;
  line-height: 1;
  font-size: 36px;
}

.panel-menu-content {
  position: absolute;
  top: 78px;
  left: 0;
  right: 0;
  bottom: 75px;
}

.panel-menu-content > .panel-list {
  -webkit-overflow-scrolling: touch;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
}

.panel-menu-heading {
  display: block;
  padding: 16px 20px;
  line-height: 1;
  margin: 0;
  text-transform: uppercase;
  text-align: center;
}

.panel-menu-heading:hover, .panel-menu-heading:focus, .panel-menu-heading:active {
  color: #000000;
}

.panel-menu .icon-back {
  position: absolute;
  left: -100%;
  opacity: 0;
  font-size: 24px;
  line-height: 20px;
  color: #c6aa28;
  transition: left .3s ease-in-out, opacity .3s ease .15s;
}

.panel-menu .panel-list {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
}

.panel-menu .panel-list > li > a {
  display: block;
  padding: 10px 20px;
  border-bottom: 1px solid #e0e0e0;
}

.panel-menu .panel-list a {
  text-transform: uppercase;
  font-size: 13px;
}

.panel-menu-footer {
  position: absolute;
  top: auto;
  left: 0;
  right: 0;
  bottom: 0;
}

.panel-menu .social-list {
  text-align: center;
  padding: 10px 0;
}

.panel-menu .social-list > li > a {
  font-size: 20px;
}

/**        ¬ level push         **/
.panel-list-level {
  position: absolute;
  top: -50px;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  transform: translate3d(-100%, 0, 0);
  background-color: #fff;
  z-index: 1;
}

/* transitions and open class */
.panel-list-current > .panel-list-item {
  transform: translate3d(0, 0, 0);
}

.panel-list-item,
.panel-list-level {
  transition-delay: 0s;
  transition-property: transform;
  transition-timing-function: ease;
  transition-duration: 400ms;
}

.panel-list-item.open,
.panel-list-level.open {
  transition-delay: 0s;
  transition-duration: 400ms;
  transition-property: transform;
  transition-timing-function: ease;
}

.panel-list-item span {
  transition: all 0.4s ease;
}

/* level open */
.panel-menu-content.open .icon-back {
  display: inline-block;
  left: 20px;
  opacity: 1;
}

.panel-menu-content.open .panel-list-current > .panel-list-item {
  transform: translate3d(100%, 0, 0);
}

.panel-list-level.open {
  transform: translate3d(0, 0, 10px);
}
